<template>
    <div class="collaborativeoffice">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">协同办公</h2>
        </div>
        <div class="productlines padUpDown" id="tabsScroll">
            <!--  <div class="container">
                <breadcrumb text="协同办公" />
            </div> -->
            <el-tabs v-model="activeName" :class="{ isScroll: scroll }" @tab-click="handleClick">
                <el-tab-pane label="即时通讯" name="first">
                    <div class="lowCode">
                        <h2>统一门户的实现</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p>圆周数科将使用钉钉+OA+邮箱整合的方式，使得企业信息传递统一门户管理，沟通与协作融为一体。在沟通交流平台，即可实现事项审批、收发文件、收发视频、聊天记录直接回复处理意见，为群组提供团队协作看板，实现消息传递及时性、办公效率的最大化，重要通知、紧急催办都使命必达。
                                </p>
                                <h2>钉钉+OA+邮箱</h2>

                                <div class="ddDesc">
                                    <div class="imgDesc">
                                        <img src="@/assets/images/productlines/dingban.png" alt="">
                                    </div>
                                    <!-- <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="12" :sm="6" class="type_item" v-for="(item, index) in dingDatas"
                                            :key="index">
                                            <div class="imgbox">
                                                <i :class="['iconfont', item.icon]"></i>
                                                <p>{{ item.name }}</p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-divider />
                                    <el-row :gutter="20" class="type_wrap type_wrap1 animate__animated animate__fadeInUp">
                                        <el-col :xs="12" :sm="6" class="type_item" v-for="(item, index) in ding2Datas"
                                            :key="index">
                                            <div class="imgbox">
                                                <i :class="['iconfont', item.icon]"></i>
                                                <p>{{ item.name }}</p>
                                            </div>
                                        </el-col>
                                    </el-row> -->
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container padUpDown">
                                <div class="IMspecific">
                                    <el-row :gutter="20" type="flex" justify="center"
                                        class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="12" :sm="4" class="type_item ">
                                            <div class="imgbox">
                                                <img src="@/assets/images/productlines/IMs1.png" alt="">
                                            </div>
                                        </el-col>
                                        <el-col :xs="12" :sm="4" class="type_item ">
                                            <div class="textDesc">
                                                <div class="leftimgdesc">
                                                    <h2> <i class="el-icon-d-arrow-left"></i> 统一门户</h2>
                                                    <p>企业内部应用一个钉钉就够了，汇聚公司所有业务系统，一个账号贯通所有。</p>
                                                </div>

                                            </div>
                                        </el-col>
                                        <el-col :xs="12" :sm="4" class="type_item ">
                                            <div class="textDesc">

                                                <div class="rightimgdesc">
                                                    <h2>代办审批 <i class="el-icon-d-arrow-right"></i></h2>
                                                    <p>所有待办事项汇聚，不再担心漏看，不用繁琐切换系统。</p>
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :xs="12" :sm="4" class="type_item ">
                                            <div class="imgbox">
                                                <img src="@/assets/images/productlines/IMs2.png" alt="">
                                            </div>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20" type="flex" justify="center"
                                        class="type_wrap1 animate__animated animate__fadeInUp">
                                        <el-col :xs="8" :sm="5" class="type_item">
                                            <div class="leftimgdesc">
                                                <h2>即时通讯 <i class="el-icon-d-arrow-right"></i></h2>
                                                <p>即时沟通、视频会议、直播、邮箱、日志、协作满足办公协同各种场景。</p>
                                            </div>
                                        </el-col>
                                        <el-col :xs="16" :sm="7">
                                            <div class="imgbox">
                                                <img src="@/assets/images/productlines/IMs3.png" alt="">
                                            </div>
                                        </el-col>
                                        <el-col :xs="12" :sm="4">
                                            <div class="imgbox">
                                                <img src="@/assets/images/productlines/IMs4.png" alt="">
                                            </div>
                                        </el-col>
                                        <el-col :xs="12" :sm="5" class="type_item">
                                            <div class="rightimgdesc">
                                                <h2><i class="el-icon-d-arrow-left"></i> 组织人事 </h2>
                                                <p>内部通讯录清晰展示组织架构，共享统一通讯录，搜索同事即可发起聊天，找人快又准。</p>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="container padUpDown">
                                <p>企业领导使用一个APP就能处理所有的待办；<br>
                                    消息必达，沟通无障碍，沟通更轻松；<br>
                                    信息云端同步，数据只跟组织走；<br>
                                    保障组织信息安全，不泄漏。</p>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="OA智慧办公" name="second">
                    <div class="lowCode">
                        <h2>全方位数字化流程办公</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    OA是数字化协同运营平台，全方位数字化流程办公，让营销，运营，管理，战略，协作互通，积累数字资产，为企业运营管理决策提供依据，实现全员全端全域的沟通协作、内外互联，无缝快捷的实现业务定制和管理扩张，助力企业数字化办公。
                                </p>
                                <div class="pro_title">
                                    <span class="chName">企业流程管理面临的挑战</span>
                                    <span class="enName">六大难题，阻碍企业制度流程化、智能化</span>
                                </div>
                                <div class="specific ">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>制度落地难</h3>
                                                    <p>对于中大型、集团型企业来说，组织结构复杂</p>
                                                    <p>制度不能得到快速贯彻、高效执行</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>部门协作难</h3>
                                                    <p>部门本位主义严重，沟通不畅</p>
                                                    <p>协作困难，跨部门业务协同效率低</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>业务处理慢</h3>
                                                    <p>无法快速进入业务系统查看和办理业务，造成审批滞后</p>
                                                    <p>无法通过查看业务数据分析报表掌握业务进度</p>

                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>工作透明度差</h3>
                                                    <p>企业越大，人员越多，管理越难</p>
                                                    <p>管理者不知道员工都在想些什么做些什么</p>

                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>管理无抓手</h3>
                                                    <p>管理者无法通过部门整体运营数据看板进行督办</p>
                                                    <p>无法有针对性的进行事项部署、公告发布和信息互动</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="8" class="type_item">
                                            <el-row :gutter="20" class="card">
                                                <div class="imgbox"></div>
                                                <el-col :span="23" class="textdesc">
                                                    <h3>协作效率低</h3>
                                                    <p>系统太多要来回切换，造成工作效率低</p>
                                                    <p>内部沟通不畅，信息分散在众多人手中，工作效率低下</p>
                                                </el-col>
                                            </el-row>
                                        </el-col>
                                    </el-row>
                                </div>
                                <div class="pro_title">
                                    <span class="chName">应用场景</span>
                                    <span class="enName">让业务数据化、管理可视化</span>
                                </div>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/appsence.png" alt="">
                                </div>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container padUpDown">
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/fivwabilities.png" alt="">
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container padUpDown">

                                <div class="oaValue ">
                                    <p class="container_title">为不同角色提供数字化办公应用
                                    </p>
                                    <p class="container_title" style="margin: 20px 0;">
                                        “管控”与“赋能”相结合，实现管理在线化、数字化、智能化相结合
                                    </p>
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="6" class="type_item" v-for="(items, index) in oaValueDatas"
                                            :key="index">
                                            <div :class="['card', `card${index + 1}`]">
                                                <div class="textdesc">
                                                    <h3>{{ items.title }} <br><strong>{{ items.title1 }}</strong></h3>
                                                    <p>{{ items.desc }}</p>
                                                    <div class="cont">
                                                        <div class="tips">
                                                            <span v-for="(item, index) in items.child" :key="index">{{ item
                                                            }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="电子签章" name="third">
                    <div class="lowCode">
                        <h2>可信的数字身份证书</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                联合契约锁电子签章，以可信数字身份证书为支撑，打通审批、签署、归档全过程，助力组织数字化转型，实现全程数字化运营，提升组织的用章效率和合规合法性。
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container padUpDown">
                                <div class="signSpecific">
                                    <div class="pro_title" style="margin-top: 0;">
                                        <span class="chName">真实可信</span>
                                        <span class="enName">真实身份认证管理 确保数字化的可信度</span>
                                    </div>
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in realbelieve"
                                            :key="index">
                                            <el-card shadow="hover" class="card">
                                                <div class="imgbox">
                                                    <i :class="[item.icon, 'iconfont']"></i>
                                                    <h3>{{ item.title }}</h3>
                                                </div>

                                                <p>{{ item.desc }}</p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                    <div class="pro_title">
                                        <span class="chName">合法有效</span>
                                        <span class="enName">具有法律效力的数字化签罢能力</span>
                                    </div>
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in legaleffect"
                                            :key="index">
                                            <el-card shadow="hover" class="card">
                                                <div class="imgbox">
                                                    <i :class="[item.icon, 'iconfont']"></i>
                                                    <h3>{{ item.title }}</h3>
                                                </div>

                                                <p>{{ item.desc }}</p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                    <div class="pro_title">
                                        <span class="chName">安全可控</span>
                                        <span class="enName">完善的授权体系，操作皆处于系统控制</span>
                                    </div>
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in safecontro"
                                            :key="index">
                                            <el-card shadow="hover" class="card">
                                                <div class="imgbox">
                                                    <i :class="[item.icon, 'iconfont']"></i>
                                                    <h3>{{ item.title }}</h3>
                                                </div>

                                                <p>{{ item.desc }}</p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                    <div class="pro_title">
                                        <span class="chName">可查可验</span>
                                        <span class="enName">让归档文件三秒可查、有效防篡改</span>
                                    </div>
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in verifiable"
                                            :key="index">
                                            <el-card shadow="hover" class="card">
                                                <div class="imgbox">
                                                    <i :class="[item.icon, 'iconfont']"></i>
                                                    <h3>{{ item.title }}</h3>
                                                </div>

                                                <p>{{ item.desc }}</p>
                                            </el-card>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="container signValue padUpDown">
                                <div class="pro_title">
                                    <span class="chName">全程数字化应用价值</span>
                                    <span class="enName">可信数字身份证书为支撑，打通审批、签署、归档全过程</span>
                                </div>
                                <ul>
                                    <li> <strong>劳动合同方面：</strong>
                                        老员工合同到期提醒、智能续签，提升人事合同管理效率；新签合同便捷线上审批；通过电子签在线签署合同；有效电子存证并归档。</li>
                                    <li> <strong>销售管理方面：</strong>
                                        销售流程智能化内部流转；合同签署对接电子签章，有效核验双方身份信息，确保双方身份可信防控风险；各类销售合同有效归档，建立可追溯体系，便捷查验。</li>
                                    <li> <strong>采购管理方面：</strong>
                                        库存不足智能预警，便捷发起采购流程；供应商权威身份认证，满足跨区域采购异地签约需求；无需打印、邮件，节约运营成本；采购完成智能存档，对接仓储平台，实时更新库存。
                                    </li>
                                    <li> <strong>项目管理方面：</strong>
                                        以流程驱动项目全过程；项目文件在招标/采购流程中，直接调用电子签线上签署；项目分包合同支持批量发起、批量盖章，确保施工进度；项目文件智能存档，便捷在线检索、核验。
                                    </li>
                                    <li> <strong>售后管理方面：</strong>
                                        客服在OA便捷发起售后业务流程，内部审批流转盖章；客户方签约无需快递，发送签署链接直接线上异地签署；签署的售后文件有效电子存证并归档，便捷在线筛选查询；对接客户管理平台，实现后续客户维护、跟踪等一体管理。
                                    </li>
                                    <li> <strong>公文管理方面：</strong>
                                        公文在线登记，一键套红，实现高效内部会签；公文签署融合数字签名技术，对接电子签章实现在线用印；提前为签署方指定签署位置，规范公文格式；流程结束有效归档，可进行防伪打印。
                                    </li>
                                    <li> <strong>工程管理方面：</strong>
                                        连接各项目部，确保施工地远程审批；工程用印无需往返，电子签署各类招采、验收文件；签署方身份智能核验，信息防伪，减少风险；各类工程文件有效归档，留痕采购、施工、验收全过程。
                                    </li>
                                    <li> <strong>招投标管理方面：</strong>
                                        便捷发起招投标流程实现内部审批；招投标文件在线云签署，解决标书等大体量签署难题；供应商、评标专家身份认证，招投标材料防伪防篡改；招投标信息有效存档，可查可验。</li>
                                    <li> <strong>分销管理方面：</strong>
                                        便捷发起流程进行内部多维度审批；加盟商、代理商身份认证，支持单方、双方及三方在线签署；分销合同文件有效电子存证及归档，便捷线上搜索及查询；智能报表多维统计分销业务数据，直观展示合同收付款情况。
                                    </li>
                                </ul>



                            </div>

                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="企业邮箱" name="fourth">
                    <div class="lowCode">
                        <h2>大规模、低成本、高效率的企业应用</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container descbox padUpDown">
                                <p class="overviewdesc">
                                    圆周数科在企业邮箱方面，与阿里云合作，选择专业做邮箱的阿里邮箱作为企业级应用产品之一。目前阿里企业邮箱拥有客户超百万家企业，每天邮件投递量超过100000000+。阿里云作为中国云计算的领导者，全球领先、安全、稳定的云产品提供商，作为奥运会全球指定服务商。同时阿里邮箱也获得了工信部第一家认证的
                                    SAAS 服务提供商，并获得了阿里邮箱三级等保证明。
                                </p>
                                <el-row :gutter="20" type="flex" justify="center" align="middle">
                                    <el-col :xs="24" :sm="6" class="imgDesc">
                                        <img alt="" src="@/assets/images/logo/logo.png">
                                    </el-col>
                                    <el-col :xs="24" :sm="6" class="imgDesc">
                                        <img alt="" src="@/assets/images/collaborators/friends7.png">
                                    </el-col>
                                    <el-col :xs="24" :sm="6" class="imgDesc">
                                        <img alt="" src="@/assets/images/collaborators/friends11.png"
                                            class="aliCloud">
                                    </el-col>
                                </el-row>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container productSpect padUpDown">
                                <!--  <div class="imgDesc">
                                    <img alt="" src="@/assets/images/productlines/productemail.png">
                                </div> -->
                                <el-row :gutter="20" class="type_wrap">
                                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in valueDatas"
                                        :key="index">
                                        <el-card shadow="hover" class="card">
                                            <div class="imgbox">
                                                <i :class="['iconfont', item.icon]"></i>
                                            </div>
                                            <div class="textbox">
                                                <h3>{{ item.title }}</h3>
                                            </div>
                                            <p>{{ item.desc }}</p>
                                        </el-card>
                                    </el-col>
                                </el-row>
                            </div>

                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container ">
                                <div class="esbSpecific padUpDown">
                                    <div class="pro_title">

                                        <img alt="" src="@/assets/images/productlines/ddemail.png"
                                            style="width: 30%">
                                        <span class="chName">强强联合，提高企业办公效率</span>
                                    </div>
                                    <ul class="imglist">
                                        <li v-for="index in 7" :key="index">
                                            <img :src="require(`@/assets/images/productlines/mailValue${index}.png`)">
                                        </li>
                                    </ul>
                                    <el-row :gutter="20" class="type_wrap">
                                        <el-col :xs="24" :sm="12" class="type_item" v-for="index in 4" :key="index">
                                            <div class="imgDesc">
                                                <img :src="require(`@/assets/images/productlines/email${index}.jpg`)">
                                            </div>
                                        </el-col>

                                    </el-row>
                                </div>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import breadcrumb from '@/components/breadcrumb.vue'
export default {
    name: "collaborativeoffice",
    components: { breadcrumb },
    data() {
        return {
            activeName: 'first',
            scroll: false,
            dingDatas: [
                { name: '统一门户', icon: 'icon-jurassic_home' },
                { name: '协同办公', icon: 'icon-windows-fill' },
                { name: '统一门户', icon: 'icon-message' },
                { name: '业务协同', icon: 'icon-fenxiang' }
            ],
            ding2Datas: [
                { name: '移动化', icon: "icon-guoji" },
                { name: '社交化', icon: "icon-qunfa" },
                { name: '智能化', icon: "icon-weibiaoti-1" },
                { name: '开放性', icon: "icon-zhuanfa" },
            ],
            realbelieve: [
                { title: '用户身份防假', icon: 'icon-shenfenzheng', desc: '基于权威数字证书，为“企业用户”以及“个人用户”提供真实身份认证和核验服务，确保操作者身份可信。' },
                { title: '关键操作防抵赖', icon: 'icon-anquanfuben', desc: '通过真实身份认证，让登录、审批、下载、签署等动作不再是虚拟的账号进行操作，而是不可抵赖的实名身份进行操作。' },
                { title: '重要数据防窃取/篡改', icon: 'icon-underline', desc: '以权威数字签名为基础采用安全加密技术，确保签署数据自动记录，电子文件在传输过程中不被窃取、不可篡改。' },
            ],
            legaleffect: [
                { title: '流程驱动签署过程', icon: 'icon-navwuliuzhongxin', desc: '通过流程驱动业务签署无缝对接审批过程与签署过程，大幅压缩签署时间。' },
                { title: '具有同等法律效力', icon: 'icon-star', desc: '电子签章产品与实体印章具有同等法律效力，满足组织日常业务中各类签署需求。' },
                { title: '有效节约签署成本', icon: 'icon-icon_', desc: '在线签署免快递来回，免线下打印，为组织节省邮寄、打印等每年大额成本支出。' },
            ],
            safecontro: [
                { title: '细化权限管理', icon: 'icon-zhiwen', desc: '业务的审批、签署过程皆由完善的授权体系进行控制，确保每一项操作都在授权范围内。' },
                { title: '智能操作留痕', icon: 'icon-shouzhi', desc: '流程驱动授权，所有印章的授权、使用、变更都有日志留痕，还原每一枚印章的操作轨迹。' },
                { title: '集团分权管控', icon: 'icon-zuzhiguanli', desc: '满足集团总部集中管控印章的需求，也能通过授权让分子公司独立管理自己的印章。' },
            ],
            verifiable: [
                { title: '全流程记录', icon: 'icon-bi', desc: '对接司法区块链，实时同步签署时间、文件哈希值等电子合同相关的数字信息，实现签署全流程的存证记录。' },
                { title: '记录不可篡改', icon: 'icon-lock-full', desc: '采用完全分布式的架构对数据加密，计算和交互在加密后数据的多个计算节点之间独立完成，无中心控制节点，真正保障不可篡改。' },
                { title: '多节点司法记录', icon: 'icon-jiedianguanli', desc: '联合杭州互联网法院司法区块链公证处、司法鉴定中心、CA、法院等国家司法机构，进行多节点的司法记录，确保了电子数据的可靠性。' },
            ],
            oaValueDatas: [
                { title: '战略管理', title1: '——面向高层管理者', desc: '确定战略与目标；基于数字化运营企业，帮助其定方向、定目标、配资源、看全局、做决策、做指挥。', child: ['目标管理', '协同驾驶仓', '企业绩效'] },
                { title: '运营管控', title1: '——面向全员、各层管理者', desc: '通过流程标准化、风险控制、流程创新与优化，实现对业务过程进行有效管理，保证流程的规范、标准、合规、可控。', child: ['流程管理', '公文管理', '督查督办'] },
                { title: '战略管理', title1: '——面向全员、生态伙伴', desc: '赋能团队生产力、赋能团队协作效率、实现数字办公、混合办公，开启组织数字化办公新范式。', child: ['团队协作', '全员沟通', '知识文档'] },
                { title: '共享服务', title1: '——面向全员、职能部门、生态伙伴', desc: '将职能管理以共享服务方式提供、以提升职能效率和集约降本、促进业务发展、支持组织变化。', child: ['财务', '人事', '采购', '法务'] }
            ],
            valueDatas: [
                { title: '安全稳固', icon: 'icon-anquanfuben', desc: '业内唯一一家拥有独立公有云服务的邮件运营商，高性能网络架构，高可用、无单点故障隐患。' },
                { title: '全球畅游', icon: 'icon-guoji', desc: '全球云机房，海外多点部署，专线链接优化国际链路，实现全球畅邮。' },
                { title: '绿色可靠', icon: 'icon-navwuliuzhongxin', desc: '垃圾邮件过滤率大于99.99%,误判率小于0.01%；邮件病毒查杀率大于99.99%。' },
                { title: '专业运营', icon: 'icon-fuwuguanli', desc: '中国最早的企业邮箱服务商之一，20年专业企业邮箱运营经验，服务超过20,000,000用户。' },
            ],
        };
    },
    mounted() {
        var str = document.getElementById("tabsScroll");
        window.addEventListener("scroll", () => {
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            this.scroll = scrollTop > str.offsetTop;
        });
    },
    methods: {
        handleClick(tab, event) {
            var el = document.getElementById('tabsScroll');
            window.scroll({
                top: el.offsetTop - 50,
                behavior: "smooth" // 平滑过渡
            });
        }
    },

};
</script>
  
<style lang="scss" scoped>
.collaborativeoffice {
    height: 100%;

    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/productlines/officeban.jpg") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .productlines {

        /* 低代码开发平台 */
        .lowCode {

            h2 {
                font-size: 24px;
                color: #04aaeb;
                text-align: center;
                letter-spacing: 5px;
                margin: 30px auto;
            }

            .overview {
                .title {

                    background: #d3dce6;

                    .container_title {

                        margin: 0 auto;
                        height: 60px;
                        line-height: 60px;
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                    }
                }

                .pro_title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    text-align: center;
                    margin-bottom: 30px;

                    span {
                        display: block;
                    }

                    .chName {
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                        margin-bottom: 20px;
                    }
                }

                .descbox {
                    .aliCloud {
                        width: 50%;
                    }
                }

                .ddDesc {
                    /*  width: 80%; */
                    margin: 0 auto;

                    .imgDesc {
                        img {
                            width: 100%;
                        }
                    }

                    .imgbox {
                        width: 150px;
                        margin: 0 auto;
                        text-align: center;
                        padding: 30px 0;
                        border: 2px solid #5c5c5c;
                        border-radius: 15px;
                        background: #dbdbdb;
                        transition: all .3s ease-in-out;

                        i {
                            font-size: 50px;
                            color: red;
                            display: block;
                            margin-bottom: 10px;
                        }

                    }

                    .type_wrap1 .imgbox {
                        background: unset;
                        border: unset;
                    }

                    .imgbox:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .IMspecific {
                    .type_wrap {
                        .imgbox {
                            img {
                                width: 100%;
                                margin: 0 auto;
                                display: block;
                                /*  box-shadow: 0px 4px 20px 2px #737373; */
                            }
                        }

                        .textDesc {
                            height: 100%;
                            position: relative;

                            h2 {
                                font-size: 18px;
                                text-align: center;
                                letter-spacing: 5px;
                                margin: 10px auto;
                                color: #666;
                                text-shadow: unset;
                            }


                            p {

                                color: #000;
                                font-size: 12px;
                                text-align: center;
                                width: 60%;
                            }

                            .leftimgdesc {
                                overflow: hidden;

                                h2 {
                                    text-align: left;
                                }

                                p {
                                    text-align: left;
                                    float: left;
                                }
                            }

                            .rightimgdesc {
                                position: absolute;
                                bottom: 10%;
                                right: 0;

                                overflow: hidden;

                                h2 {
                                    text-align: right;
                                }

                                p {
                                    text-align: right;
                                    float: right;
                                }
                            }

                        }
                    }

                    .type_wrap1 {
                        margin-top: 30px;

                        .imgbox {
                            img {
                                width: 100%;
                                margin: 0 auto;
                                display: block;
                                /*   box-shadow: 0px 4px 20px 2px #737373; */
                            }
                        }

                        .type_item {
                            position: relative;

                            h2 {
                                font-size: 18px;
                                text-align: center;
                                letter-spacing: 5px;
                                margin: 10px auto;
                                color: #666;
                                text-shadow: unset;
                            }

                            p {
                                color: #000;
                                font-size: 12px;
                                text-align: center;
                            }


                            .leftimgdesc {
                                position: absolute;
                                top: 10%;
                                right: 10%;
                                overflow: hidden;

                                h2 {
                                    text-align: right;
                                }

                                p {
                                    text-align: right;
                                    float: right;
                                }

                            }

                            .rightimgdesc {
                                position: absolute;
                                bottom: 10%;
                                left: 10%;
                                overflow: hidden;

                                h2 {
                                    text-align: left;
                                }

                                p {
                                    text-align: left;
                                    float: left;
                                }

                            }
                        }
                    }
                }

                .signValue {
                    ul {
                        list-style: initial;

                        li {
                            line-height: 30px;
                            margin-bottom: 10px;
                        }
                    }
                }



                .specific {

                    .card {

                        text-align: center;
                        width: 100%;
                        margin-bottom: 40px;
                        transition: all .3s ease-in-out;
                        padding: 20px 0;
                        display: flex;
                        justify-content: start;

                        .imgbox {
                            width: 5px;
                            height: 100px;
                            background: #04aaeb;
                            box-shadow: 0px 0px 5px #04aaeb;

                        }

                        .textdesc {
                            padding-left: 30px !important;

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin-bottom: 10px;
                                margin-top: 0;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;

                            }
                        }


                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .oaValue {
                    p {
                        text-align: center;
                    }

                    .card {
                        height: 300px;
                        text-align: center;
                        width: 100%;
                        margin-bottom: 40px;
                        transition: all .3s ease-in-out;
                        background-image: url("@/assets/images/productlines/oaValue1.jpg");
                        background-size: cover;


                        .textdesc {
                            height: calc(100% - 60px);
                            padding: 30px !important;
                            color: #fff;
                            background-color: rgba(0, 0, 0, 0.6);
                            position: relative;



                            h3 {
                                font-size: 16px;
                                margin-bottom: 10px;
                                margin-top: 0;

                                strong {
                                    font-size: 13px;
                                }
                            }

                            p {
                                margin-top: 30px;
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;

                            }

                            .cont {
                                position: absolute;
                                bottom: 30px;
                                left: 0;
                                width: 100%;

                                .tips {
                                    display: flex;
                                    justify-content: center;
                                    flex-wrap: wrap;

                                    span {
                                        padding: 0px 8px;
                                        font-size: 12px;
                                        border: 1px solid #a7a7a7;
                                        color: #d9d9d9;
                                        background: black;
                                        margin: 5px 3px;
                                    }

                                }
                            }

                        }


                    }

                    .card2 {
                        background-image: url("@/assets/images/productlines/oaValue2.jpg");
                    }

                    .card3 {
                        background-image: url("@/assets/images/productlines/oaValue3.jpg");
                    }

                    .card4 {
                        background-image: url("@/assets/images/productlines/oaValue4.jpg");
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .masterdataSpecific {
                    .card {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 40px;
                        transition: all .3s ease-in-out;
                        padding: 20px 0;

                        .imgbox {
                            margin-bottom: 30px;

                            .iconfont {
                                font-size: 60px;
                                color: #04aaeb;
                            }

                        }

                        .textdesc {
                            padding-left: 30px !important;

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin-bottom: 10px;
                                margin-top: 0;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                                text-align: left;

                            }
                        }


                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .signSpecific {
                    .pro_title {
                        margin-top: 50px;
                    }

                    .card {
                        height: 240px;
                        text-align: center;

                        .imgbox {
                            position: relative;
                            display: inline-block;

                            padding: 20px 0 0;


                            img {
                                width: 50%;

                                display: inline-block;
                                vertical-align: middle;
                                transition: All 0.4s ease-in-out;
                                -webkit-transition: All 0.4s ease-in-out;
                                -moz-transition: All 0.4s ease-in-out;
                                -o-transition: All 0.4s ease-in-out;
                            }

                            i {
                                font-size: 40px;
                                color: #04aaeb;
                            }
                        }

                        /*  .imgbox::before {
                            background: #B8CAD2;
                            border: 1px solid #777777;
                            z-index: -1;
                            position: absolute;
                            content: '';
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            transform: skewX(-30deg);
                        }
 */
                        h3 {
                            color: #333;
                            font-size: 16px;
                            margin: 15px auto 5px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            width: 80%;
                            margin: 0 auto;
                        }
                    }

                    .card:hover {
                        transform: translate(0, -10px);
                    }
                }

                .productSpect {
                    .card {
                        /* height: 430px; */
                        min-height: 280px;
                        text-align: center;
                        -webkit-transition: all 0.5s linear;
                        transition: all 0.5s linear;

                        .imgbox {

                            height: 100px;
                            margin: 0 auto;
                            text-align: center;
                            line-height: 100px;

                            i {
                                font-size: 60px;
                                color: #04aaeb;
                            }
                        }

                        .textbox {
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 10px 0 0;

                            h3 {
                                color: #333;
                                font-size: 16px;
                            }
                        }

                        p {
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }

                    .card:hover {
                        -webkit-transform: translate(0, -10px);
                        transform: translate(0, -10px);

                        img {
                            transform: scale(1.2);

                        }
                    }
                }

                .esbSpecific {
                    .imglist {
                        list-style: none;
                        overflow: hidden;

                        li {
                            img {
                                width: 70%;
                                display: block;
                            }
                        }

                        li:nth-of-type(odd) {
                            img {
                                float: left;
                            }
                        }

                        li:nth-of-type(even) {
                            img {
                                float: right;
                            }

                        }
                    }

                    .imgDesc {
                        img {
                            width: 100%;
                        }
                    }

                }

                .solution {
                    .card {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        height: 150px;
                        margin-bottom: 20px;
                        padding: 20px;
                        box-shadow: 0px 5px 25px 0px rgba(195, 195, 195, 0.2);
                        -webkit-transition: all 0.5s linear;
                        transition: all 0.5s linear;

                        .imgbox {
                            overflow: hidden;
                            height: 50px;
                            width: 50px;
                            margin-right: 20px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                transition: all 0.6s;
                            }

                            i {
                                font-size: 40px;
                                color: #04aaeb;
                            }
                        }

                        .contbox {
                            padding: 20px;
                            position: relative;
                            height: 200px;

                            h3 {
                                color: #333;
                                font-size: 20px;
                                margin: 0 0 20px;
                            }

                            .personalInfo {
                                position: absolute;
                                bottom: 0;
                                width: 100%;

                                img {
                                    width: 30px;
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                span {
                                    font-size: 12px;
                                    line-height: 20px;
                                    display: inline-block;
                                    margin-left: 10px;
                                }

                                a {
                                    float: right;
                                    color: #04aaeb;
                                }
                            }
                        }
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);

                        .imgbox img {
                            transform: scale(1.2);
                        }
                    }
                }

                .keyValue {
                    .card {
                        margin-bottom: 20px;
                        transition: all .3s ease-in-out;
                        padding: 0 20px 20px;

                        h3 {
                            color: #333;
                            font-size: 16px;
                            line-height: 60px;
                            margin-top: 0;

                            .iconfont {
                                font-size: 26px;
                                color: #04aaeb;
                                margin-right: 20px;
                                vertical-align: middle;
                            }
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                            text-align: left;

                        }
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .imgDesc {
                    text-align: center;

                    img {
                        width: 80%;
                    }
                }

                .overviewdesc {
                    margin-bottom: 50px;
                }

            }


        }
    }


}
</style>
  