<template>
  <div id="app">

    <el-container>
      <el-header class="header" :class="scrollFlag ? 'scroll' : ''">
        <div class="container" style="position: sticky;">
          <div class="nav">
            <router-link :to="'/'">
              <img alt="" class="logo" src="@/assets/images/logo/logo.png"></router-link>
            <!-- pc段导航栏 >768-->
            <el-menu router :default-active="activeIndex" class="hidden-xs-only" mode="horizontal">
              <el-menu-item index="/">首页</el-menu-item>
              <!--  <el-menu-item index="/productlines">产品线</el-menu-item> -->
              <el-submenu index="1" popper-class="fixedSub">
                <template slot="title">产品线</template>
                <el-menu-item index="/basicsoftware">基础软件</el-menu-item>
                <el-menu-item index="/smartmanufacturing">智能制造</el-menu-item>
                <el-menu-item index="/bigdata">大数据</el-menu-item>
                <el-menu-item index="/collaborativeoffice">协同办公</el-menu-item>
              </el-submenu>
              <el-submenu index="2" popper-class="fixedSub">
                <template slot="title">解决方案</template>
                <el-menu-item index="/intelligentsolution">智能制造解决方案</el-menu-item>
                <el-menu-item index="/bigdatasolution">大数据分析解决方案</el-menu-item>
              </el-submenu>
              <el-menu-item index="/collaborators">合作伙伴</el-menu-item>
              <el-menu-item index="/aboutUs">关于我们</el-menu-item>
              <el-menu-item index="/joinUs">加入我们</el-menu-item>
              <!--  <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
            </el-menu>
            <!-- 手机端导航栏 <=768 -->
            <el-button class="hidden-sm-and-up" @click="showBtn"><i class="iconfont icon-liebiao"></i></el-button>
            <el-drawer :visible.sync="drawer" direction="ttb" :with-header="false" append-to-body size="60%">
              <el-menu router :default-active="activeIndex" :collapse="isCollapse">
                <el-menu-item index="/">首页</el-menu-item>
                <!-- <el-menu-item index="/productlines">产品线</el-menu-item> -->
                <el-submenu index="1">
                  <template slot="title">产品线</template>
                  <el-menu-item index="/basicsoftware">基础软件</el-menu-item>
                  <el-menu-item index="/smartmanufacturing">智能制造</el-menu-item>
                  <el-menu-item index="/bigdata">大数据</el-menu-item>
                  <el-menu-item index="/collaborativeoffice">协同办公</el-menu-item>
                </el-submenu>
                <el-submenu index="2">
                  <template slot="title">解决方案</template>
                  <el-menu-item index="/intelligentsolution">智能制造解决方案</el-menu-item>
                  <el-menu-item index="/bigdatasolution">大数据分析解决方案</el-menu-item>
                </el-submenu>
                <el-menu-item index="/collaborators">合作伙伴</el-menu-item>
                <el-menu-item index="/aboutUs">关于我们</el-menu-item>
                <el-menu-item index="/joinUs">加入我们</el-menu-item>
              </el-menu>
            </el-drawer>
          </div>
        </div>
      </el-header>
      <!-- <transition mode="out-in"> -->
      <router-view></router-view>
      <!--  </transition> -->
      <el-footer class="footer">
        <div class="container">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="9">
              <h2 class="title">快捷导航 <i class="icon-lianjie iconfont"></i></h2>
              <el-row :gutter="20" type="flex" justify="space-between">
                <el-col :sm="6">
                  <dl class="tabItem">
                    <dt>产品线</dt>
                    <dd>
                      <router-link to="/basicsoftware">基础软件</router-link>
                    </dd>
                    <dd>
                      <router-link to="/smartmanufacturing">智能制造</router-link>
                    </dd>

                    <dd>
                      <router-link to="/bigdata">大数据</router-link>
                    </dd>

                    <dd>
                      <router-link to="/collaborativeoffice">协同办公</router-link>
                    </dd>
                  </dl>
                </el-col>
                <el-col :sm="10">
                  <dl class="tabItem">
                    <dt>解决方案</dt>
                    <dd>
                      <router-link to="/intelligentsolution">智能制造解决方案</router-link>
                    </dd>

                    <dd>
                      <router-link to="/bigdatasolution">大数据分析解决方案</router-link>
                    </dd>
                  </dl>
                </el-col>
                <el-col :sm="8">
                  <dl class="tabItem">
                    <dt>关于我们</dt>
                    <dd>
                      <router-link to="/aboutUs">圆周简介</router-link>
                    </dd>
                    <dd>
                      <router-link to="/aboutUs">企业文化</router-link>
                    </dd>
                    <dd>
                      <router-link to="/joinUs">加入我们</router-link>
                    </dd>
                  </dl>
                </el-col>
              </el-row>
            </el-col>
            <el-col :xs="24" :sm="4" class="imgbox">
              <img alt="" src="@/assets/images/logo/code.jpg" style="width: 100%;">
              <p> <i class="icon-saoyisao iconfont"></i> 扫一扫 咨询我们</p>
            </el-col>
            <el-col :xs="24" :sm="11" class="firmInfo">
              <img alt="" src="@/assets/images/logo/logo.png">
              <h3>江苏圆周数字科技有限公司</h3>
              <p>地址：江苏省镇江市润州区茶砚山路阿里云创新中心2楼207</p>
              <p>电话： <span>0511-8560-8166</span></p>
              <p>网址：www.yuanzhoudt.com</p>
            </el-col>
          </el-row>
          <div class="filebox">
            <div class="leftInfo">Copyright © 2023 江苏圆周数字科技有限公司 All Rights Reserved
              <a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2023027403号-1</a>
              <img src="@/assets/images/logo/ba.png">
              <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32111102000401">苏公网安备
                32111102000401号</a>
            </div>
            <div>Designed by 圆周数科 </div>
          </div>
        </div>
      </el-footer>

    </el-container>
    <!-- 返回顶部-->
    <div @click="goTop" class="goTop" v-show="toTopshow">
      <i class="el-icon-top"></i>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: 'App',
  data() {
    return {
      activeIndex: '/',
      scrollFlag: false,
      drawer: false,
      toTopshow: false,
      isCollapse: false,
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.activeIndex = val.path
        /*  this.isCollapse = true */
        if (val.name !== oldVal.name) {
          this.drawer = false
        }
      },

      deep: true
    }
  },
  destroyed() {
    // 清空缓存数据
    this.drawer = false;
  },
  created() {
    this.activeIndex = this.$route.path
  },
  mounted() {

    window.addEventListener("scroll", () => {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollFlag = scrollTop > 10;
    });
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    ...mapMutations(["setActive",]),
    // 点击折叠按钮
    showBtn() {
      // 取反，改变当前的状态
      this.drawer = !this.drawer

    },
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 300 ? (this.toTopshow = true) : (this.toTopshow = false);
    },
    goTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
          50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  text-shadow: none;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

#app {
  font-family: "PingFangM", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  line-height: 25px;
}

.padUpDown {
  padding: 20px 0;
  line-height: 25px;
}

.sideNav {
  position: fixed;
  right: 10px;
  bottom: 30%;
  background: #fff;
  list-style: none;
  box-shadow: 0 0 10px 0 rgba(13, 35, 62, .1);
  z-index: 2;

  li {
    padding: 10px 20px;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
    font-size: 12px;
  }

  li:last-child {
    border-bottom: unset
  }


  li:hover {
    color: #f9b800;
  }

  .active,
  li:visited {
    /*  color: #fff !important;
    background: #f9b800; */
    color: #f9b800;
  }
}

.el-button--primary {
  background-color: #04aaeb !important;
  border-color: #04aaeb !important;
}


.el-header {
  padding: 0 !important;
}

/* .container {
    width: 1200px;
    margin: 0 auto;
  }
  
  .container_title {
    width: 1180px;
    margin: 0 auto;
  } */

.header {
  position: fixed;
  background-color: rgb(0 0 0 / 50%);
  top: 0;
  z-index: 9;
  width: 100%;
}



.el-header {
  padding: 0 !important;
}

.nav {
  display: flex;
  justify-content: space-between;

  a {
    display: inherit !important;
  }

  .logo {
    margin: 15px 0 !important;
    height: 30px !important;
  }

}


.el-card {
  margin: 10px 0;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-menu {
  background-color: unset !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: unset !important;
  margin-top: 10px !important;
}

.el-menu--horizontal>.el-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  border: none !important;
  color: #fff !important;
}




.scroll {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(133, 138, 180, 0.14);

  .el-menu--horizontal>.el-menu-item {
    color: #909399 !important;
  }

  .el-submenu .el-submenu__title {
    color: #909399 !important;
  }
}

.el-menu--horizontal>.el-menu-item.is-active {
  color: #fff !important;
  background: #04aaeb !important;
}

.el-menu .el-menu-item:visited {
  border-bottom: unset !important;
  color: #fff !important;
}

.el-menu-item:hover {
  border-bottom: unset !important;
  color: #04aaeb !important;
  background: transparent !important;
}



.el-submenu .el-submenu__title {
  height: 40px !important;
  line-height: 40px !important;
  color: #fff !important;
  border-bottom: unset !important;
}

.el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
  color: #04aaeb !important;

  i {
    color: #04aaeb !important;
  }
}

.el-submenu.is-active .el-submenu__title,
.el-submenu .el-submenu__title:visited {
  background: #04aaeb !important;
  color: #fff !important;

  i {
    color: #fff !important;
  }
}

.el-menu--popup .el-menu-item:hover,
.el-menu--popup .el-menu-item:visited {
  color: #04aaeb !important;
  background: #fff !important;

}

.el-menu--popup .el-menu-item.is-active {
  background: #04aaeb !important;
  color: #fff !important;
}

/* 底部导航样式 */
.footer {
  height: unset !important;
  /*   background: linear-gradient(to bottom, #fff, #04aaeb); */
  padding: 20px 0 !important;
  background: #2e2e2e;

  .title {
    margin-bottom: 30px;
    color: #fff;

    .iconfont {
      font-size: 20px;
      margin-left: 6px;
    }
  }

  .imgbox {
    text-align: center;
    margin-top: 40px;

    .iconfont {
      font-size: 14px;
      margin-right: 6px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      margin-top: 10px;
      color: #a7a7a7;
    }
  }

  .tabItem {

    /*  text-align: center; */
    dt {
      margin-bottom: 23px;
      font-size: 16px;
      color: #fff;
      text-decoration: none;
      font-weight: 600;
    }

    dd {
      margin-bottom: 10px;

      a {
        font-size: 14px;
        color: #a7a7a7;
        text-decoration: none;
      }

      a:hover {
        color: #f9b800;
      }
    }
  }

  .firmInfo {
    text-align: right;
    /*    line-height: 30px; */
    float: right;

    img {
      width: 200px;
      margin: 0px 0 20px;
    }


    h3,
    p {
      color: #a7a7a7;
      margin-bottom: 10px;

      span {
        font-size: 32px;
        color: #f9b800;
      }
    }

    h3 {
      margin: 10px 0 20px;
      letter-spacing: 3px;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .filebox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0 15px;
    font-size: 13px;
    color: #a7a7a7;
    border-top: 1px solid #a7a7a7;
    margin-top: 20px;

    .leftInfo {
      display: flex;
      align-items: center;

      a {
        display: inline-block;
        color: #a7a7a7;
        text-decoration: none;
        margin: 0 5px;
      }

      a:hover {
        color: #f9b800;
      }

      img {
        width: 15px;
      }
    }
  }
}



@media (max-width: 768px) {
  .el-dialog__wrapper .el-dialog {
    width: 320px !important;

    .el-dialog__body {
      padding: 10px 20px !important;

      .caveat {
        padding: 0 !important;
        border: unset !important;

        i {
          display: block !important;
        }

        h3,
        p {
          font-size: 18px;
          display: flex;
          justify-self: start;
        }

        p {
          margin-bottom: 10px !important;
          line-height: unset !important;
        }
      }
    }
  }

  #showBtn {
    display: block !important;
  }

  .isScroll .el-tabs__header {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: 100% !important;
    background: unset !important;
    z-index: 1 !important;
    box-shadow: unset !important;
  }

  .nav {
    padding: 0 10px !important;
    align-items: center !important;

    .logo {
      /*    width: 150px !important; */
      height: 40px;
    }
  }

  .el-menu {
    background-color: #fff !important;

    .el-menu-item,
    .el-submenu__title {
      width: 100% !important;
      color: #333 !important;
    }

    .el-menu-item.is-active {
      background: #04aaeb !important;
      color: #fff !important;
    }

    .el-submenu .el-menu-item.is-active {
      color: #04aaeb !important;
      background: #fff !important;
    }

  }


  .banner,
  .banner .el-carousel__container {
    height: 36vh !important;

    h2 {
      font-size: 20px !important;
      margin-bottom: 15px !important;
      letter-spacing: unset !important;
      /*  line-height: 60px */
    }

    h3 {
      font-size: 16px !important;
      line-height: unset !important;
    }
  }

  .bannerImg .textbox {
    top: 45% !important;
  }

  .news .el-carousel__container {
    height: unset !important;
    min-height: 680px !important;

    .el-row {
      flex-wrap: wrap !important;
    }

    .imgbox {
      height: 220px !important;
      width: 100% !important;
      /*    max-height: 220px !important; */
      overflow: hidden;

      img {
        height: 90% !important;
        border-radius: 0 !important;
      }
    }

    .newCon {
      h3 {
        font-size: 16px !important;
        line-height: 20px !important;
      }

      .textDesc {
        max-height: 400px !important;
        overflow-y: auto !important;
      }
    }
  }

  .container {
    width: unset !important;
  }

  .padUpDown {
    padding: 20px 10px;
  }

  .footer {
    padding: 20px !important;

    .imgbox {
      margin-top: 20px !important;

      img {
        width: 40% !important;
      }
    }

    .filebox {
      flex-wrap: wrap !important;
    }

    .firmInfo {
      margin-top: 30px !important;
      text-align: left !important;

      p {
        margin-bottom: 10px !important;
      }
    }
  }

  //首页
  /*  .index { */
  .cando {
    padding: 20px !important;

    h2 {
      font-size: 24px !important;
      margin-bottom: 12px;
    }

    .title {
      font-size: 20px !important;
      letter-spacing: unset !important;
    }

    .card {
      height: unset !important;
    }
  }

  .subhead {
    padding: 0 !important;
  }

  .container_title {
    width: unset !important;
    margin-left: 20px;
  }

  .pro_title {
    margin-bottom: 0 !important;

    .chName {
      font-size: 20px !important;
    }
  }

  .textbox {
    h2 {
      letter-spacing: 4px !important;
      line-height: 20px !important;
    }

    h3 {
      letter-spacing: 0 !important;
      margin-top: 0 !important;
    }
  }

  .productlines {
    padding: 20px !important;

    h2 {
      font-size: 20px !important;
      margin: 10px auto 30px !important;
    }

    .card {
      height: unset !important;
      min-height: unset !important;
      padding-bottom: 20px !important;

      .imgbox {
        /*  height: 200px !important; */
        margin-bottom: 15px !important;
      }
    }
  }

  .solution {
    padding: 20px !important;

    .type_wrap {
      padding: 0 !important;
      background: transparent !important;
      margin-bottom: 15px !important;

      .contbox {
        height: unset !important;

        .personalInfo {
          position: initial !important;
          margin-top: 15px;

          span {
            font-size: 12px !important;
            max-width: 68% !important;
          }
        }

        p {
          font-size: 14px !important;
        }

      }
    }

  }

  .partners {
    .card {
      height: 80px !important;

      img {
        width: 50% !important;
      }

      .imgSize1,
      .imgSize7 {
        width: 100% !important;
      }

      .imgSize5,
      .imgSize9 {
        width: 80% !important;
      }

      .imgSize4,
      .imgSize11,
      .imgSize12 {
        width: 60% !important;
      }


    }

  }

  .subban {
    height: 200px !important;
    padding: 0 !important;
    line-height: 50px !important;

    .con {
      bottom: 38px !important;
      font-size: 16px !important;
      left: 10% !important;

      .chName {
        font-size: 36px !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .mapbox {
    padding: 30px 20px !important;

    .map {
      height: 300px !important;
    }

    .mapInfo {
      height: unset !important;
      margin-top: 20px !important;

      i {
        transform: rotate(90deg) !important;
      }
    }
  }

  .collaborators {
    .partnersIntroducte {
      .type_item {

        height: unset !important;
        padding-top: 80px !important;
      }

      .card {
        border: 1px solid #ccc !important;

        .imgbox {
          border: 1px solid #ccc !important;
        }

        .desc {
          position: unset !important;
        }

      }
    }
  }

  .overviewdesc {
    margin-bottom: 20px !important;
  }

  .imgDesc {
    img {
      width: 100% !important;
    }
  }

  /* 加入我们 */

  .joinUs {


    .contactUsCon {
      padding: 20px !important;

      .joinCon {
        padding: 20px 0 10px !important;

        .duty {
          padding: 0 10px 20px 30px !important;
        }
      }


      .jobItem {
        padding: 20px !important;

        .address {
          display: inline-block;
        }

        .tabs {
          span {
            padding: 0 !important;
            border: unset !important;
            margin-bottom: 10px !important;
            margin-right: 10px !important;
          }
        }

        .contactsUsbtn {
          /*   margin-top: 10px !important; */
          margin-left: 0 !important;
          float: unset !important;
        }
      }
    }
  }

  .pro_title {
    margin-bottom: 20px !important;

    .chName {
      margin-bottom: 8px !important;
      letter-spacing: 5px !important;
    }

    .enName {
      letter-spacing: 3px !important;
    }
  }

  /* 关于我们 */
  .aboutUs {
    .history {
      .type_wrap {
        margin-top: 30px !important;
      }
    }

    .officeEnvironment .type_wrap {
      justify-content: space-around !important;
      flex-wrap: wrap !important;
    }

    .cultures {


      .type_wrap {
        margin-bottom: 15px !important;

        .imgbox {
          height: unset !important;
        }

        .contbox {

          padding-top: 0 !important;

          h3 {
            font-size: 16px !important;
            margin-bottom: 5px !important;
          }

          p {
            font-size: 14px !important;
          }
        }
      }

    }

    .contactUsCon {
      padding: 0 !important;

      .caveat {
        p {
          margin-bottom: 0 !important;
        }
      }

      .el-form {
        .el-form-item {
          display: flex !important;
          flex-wrap: wrap !important;
          margin-bottom: 10px !important;

          .el-form-item__label {
            text-align: left !important;
          }

          .el-form-item__content {
            margin-left: 0 !important;
            width: 100% !important;
          }
        }

      }
    }

  }

  //大数据解决方案
  .bigdatasolution {
    .solutionText {
      h2 {
        line-height: unset !important;

      }

      p {
        min-width: 100% !important;
        line-height: 16px !important;
        font-size: 12px !important;
      }
    }

    .industry {
      .el-row {
        flex-wrap: wrap !important;
      }
    }

    .waysAdvantage {
      .card {
        height: unset !important;
        padding-bottom: 30px !important;
        margin-bottom: 20px !important;

        .imgbox {
          height: 200px !important;
        }
      }
    }

    .operatchanges {
      .card {
        height: unset !important;
      }
    }

    .bigdataview .imgbox {
      height: 200px !important;
    }

  }

  //智能制造
  .intelligentsolution {
    .solutionText {
      h2 {
        line-height: unset !important;

      }

      p {
        min-width: 100% !important;
        line-height: 14px !important;
        font-size: 12px !important;
        margin-top: 3px !important;
      }
    }

    .bigdataview .card {
      height: unset !important;
      padding: 20px !important;
    }

    .waysAdvantage {
      .card {
        height: unset !important;
        padding-bottom: 30px !important;
        margin-bottom: 20px !important;

        .imgbox {
          height: 200px !important;
        }
      }
    }

    .operatchanges .card {
      height: unset !important;
    }
  }

  //智能制造
  .smartmanufacturing {
    .lowCode .overview {
      .title {
        padding: 0 20px !important;
      }

      .masterdataSpecific {
        margin-bottom: 0 !important;

        .card {
          margin-bottom: 20px !important;
          /* min-height: unset !important; */
          border: 1px solid #f1f1f1 !important;
          list-style-type: none !important;

          .imgbox {
            height: 70px !important;
            list-style: 70px !important;
          }

          .painpoint {
            min-height: 200px !important;
            margin-bottom: 0 !important;
          }

          p {
            padding: 0 10px !important;
          }
        }


      }

      .keyValue {
        .type_wrap {
          flex-wrap: wrap !important;

          .card {
            width: 100% !important;
            text-align: center !important;
            list-style-type: none !important;

          }
        }
      }

      .produceDesc {
        .type_wrap {
          margin-bottom: 15px !important;

          .imgbox {
            height: 100px !important;
            margin: 0 auto !important;

          }

          .contbox {
            max-height: unset !important;
            padding-top: 0 !important;

            h3 {
              font-size: 16px !important;
              margin-bottom: 5px !important;
            }

            p {
              font-size: 14px !important;
            }
          }
        }
      }

      .biOverview {
        .type_wrap {
          flex-wrap: wrap !important;
        }

      }
    }

  }

  //大数据
  .bigdata {
    .container_title {
      padding: 0 20px !important;
    }

    .biOverview {
      padding: 0 !important;

      .card {
        padding: 0 20px !important;
      }
    }

    .BIIntelligence {
      .imgbox {
        height: unset !important;
      }
    }
  }

  //基础软件
  .basicsoftware {
    .container_title {
      padding: 0 20px !important;
    }

    .specific .card {
      padding-bottom: 20px !important;
      margin-bottom: 20px !important;

      .imgbox {
        height: unset !important;
      }
    }

    .keyValue {
      .card {
        padding: 20px !important;

        h3 {
          line-height: unset !important;
          display: flex;
          margin-bottom: 10px !important;
        }
      }


    }

    .masterdataSpecific {
      .type_item {
        min-height: 200px !important;

        .card {
          margin-bottom: 20px !important;
          height: 140px !important;
          padding: 20px 0 20px !important;

          .imgbox {
            height: 60px !important;
            line-height: 60px !important;
          }

          h3 {
            margin: 0 15px !important;
          }
        }
      }

    }

    .esbSpecific {
      .card .imgbox {
        height: 70px !important;
      }
    }

  }

  //协同办公
  .collaborativeoffice {
    .container_title {
      padding: 0 20px !important;
    }



    .ddDesc {
      width: 100% !important;

      .imgbox {
        width: unset !important;
        max-width: 150px !important;
        margin-bottom: 15px !important;
      }
    }

    .rightimgdesc,
    .leftimgdesc {
      h2 {
        font-size: 14px !important;
        margin: 10px auto !important;
      }

      p {
        line-height: unset !important;
        font-size: 12px !important;
      }
    }

    .rightimgdesc {
      bottom: 0 !important;
    }

    .specific {
      .card {
        padding-top: 20px !important;
        margin-bottom: 20px !important;

        .imgbox {
          height: unset !important;
        }
      }

    }

    .signSpecific {
      .card {
        height: unset !important;

        .imgbox {
          width: 70% !important;
          height: unset !important;
        }

        p {
          width: 100% !important;
        }
      }
    }

    .oaValue {
      .card {
        height: 300px !important;
        padding-bottom: 0 !important;
        margin-bottom: 20px !important;
      }
    }

    .descbox {
      .el-row {
        flex-wrap: wrap !important;
      }

      .imgDesc {
        img {
          width: 100% !important;
        }

        .aliCloud {
          width: 70% !important;
          margin-top: 40px !important;
        }
      }
    }

    .esbSpecific {
      .pro_title {
        img {
          width: 70% !important;

        }

        .chName {
          font-size: 18px !important;
        }
      }

      .imglist li img {
        width: 100% !important;

      }

    }

    .productSpect {
      .card {
        height: unset !important;
        min-height: unset !important;
        padding-bottom: 0 !important;

        .imgbox {
          line-height: 50px !important;
          height: 50px !important;
          margin-bottom: 0 !important;
        }

        .textbox {
          height: 40px !important;
        }
      }

    }

    .IMspecific {

      .type_wrap,
      .type_wrap1 {
        flex-wrap: wrap !important;
        margin-top: 0 !important;

        .type_item {
          min-height: 180px !important;
          margin-bottom: 20px !important;
        }

        .imgbox {
          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-device-width : 768px) {
  .el-menu {
    background-color: unset !important;
  }

  .el-menu .el-menu-item,
  .el-menu .el-submenu__title {
    width: unset !important;

  }


  .el-menu.el-menu--horizontal {
    border-bottom: unset !important;
    margin-top: 10px !important;
  }

  .el-menu--horizontal>.el-menu-item {
    height: 40px !important;
    line-height: 40px !important;
    border: none !important;
    color: #fff !important;
  }




  .scroll {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(133, 138, 180, 0.14);

    .el-menu--horizontal>.el-menu-item {
      color: #909399 !important;
    }

    .el-submenu .el-submenu__title {
      color: #909399 !important;
    }
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    color: #fff !important;
    background: #04aaeb !important;
  }

  .el-menu .el-menu-item:visited {
    border-bottom: unset !important;
    color: #fff !important;
  }

  .el-menu-item:hover {
    border-bottom: unset !important;
    color: #04aaeb !important;
    background: transparent !important;
  }



  .el-submenu .el-submenu__title {
    height: 40px !important;
    line-height: 40px !important;
    color: #fff !important;
    border-bottom: unset !important;
  }

  .el-submenu .el-submenu__title:hover {
    background-color: transparent !important;
    color: #04aaeb !important;

    i {
      color: #04aaeb !important;
    }
  }

  .el-submenu.is-active .el-submenu__title,
  .el-submenu .el-submenu__title:visited {
    background: #04aaeb !important;
    color: #fff !important;

    i {
      color: #fff !important;
    }
  }

  .partnersIntroducte .type_item {
    padding-top: 80px !important;

    .card {
      height: 190px !important;
      border: 1px solid #ccc !important;
    }

    .card:hover {
      border: 1px solid #04aaeb !important;

      .imgbox {
        border: 1px solid #04aaeb !important;
      }

    }
  }

}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .container {
    width: 740px;
    margin: 0 auto;
  }

  .overview .container_title,
  .container_title {
    width: 720px;
    margin: 0 auto;
  }

  .cando .card,
  .aboutUscon .card {
    min-height: 350px !important;

    .textbox h3 {
      font-size: 15px !important;
    }

  }

  .basicsoftware .specific .card {
    min-height: 180px !important;
  }

  .basicsoftware .keyValue .card {
    height: 190px !important;
  }

  .basicsoftware .esbSpecific .card {
    height: 360px !important;
  }

  .basicsoftware .esbSpecific .cardBpm {
    height: 280px !important;
  }

  .smartmanufacturing .productValue .card {
    min-height: 340px !important;
  }

  .smartmanufacturing .productValue .apscard {
    min-height: 220px !important;
  }

  .smartmanufacturing .produceDesc .card {
    min-height: 180px !important;
  }

  .smartmanufacturing .masterdataSpecific .card {
    min-height: 220px !important;
  }

  .smartmanufacturing .masterdataSpecific .wmscard {
    min-height: 150px !important;
  }

  .bigdata .keyValue .card {
    min-height: 280px !important;
  }

  .collaborativeoffice .oaValue .card {
    height: 410px !important;
  }

  .collaborativeoffice .productSpect .card {
    min-height: 300px !important;
  }

  .bigdatasolution .operatchanges .card,
  .intelligentsolution .operatchanges .card {
    height: 330px !important;
  }

  .bigdatasolution .waysAdvantage .card {
    height: 350px !important;
  }

  .intelligentsolution .waysAdvantage .card {
    height: 390px !important;
  }

  .collaborators .partnersIntroducte .card {
    height: 230px !important;
  }

}

@media screen and (min-width: 920px) and (max-width: 1200px) {
  .container {
    width: 860px;
    margin: 0 auto;
  }

  .overview .container_title,
  .container_title {
    width: 900px;
    margin: 0 auto;
  }

  .cando .card,
  .aboutUscon .card {
    min-height: 370px !important;
  }

  .basicsoftware .specific .card {
    min-height: 180px !important;
  }

  .basicsoftware .keyValue .card {
    height: 140px !important;
  }

  .basicsoftware .esbSpecific .card {
    height: 280px !important;
  }

  .basicsoftware .esbSpecific .cardBpm {
    height: 300px !important;
  }

  .smartmanufacturing .produceDesc .card {
    min-height: 160px !important;
  }

  .smartmanufacturing .masterdataSpecific .card {
    min-height: 220px !important;
  }

  .smartmanufacturing .masterdataSpecific .wmscard {
    min-height: 160px !important;
  }

  .bigdata .keyValue .card {
    min-height: 260px !important;
  }

  .collaborativeoffice .oaValue .card {
    height: 380px !important;
  }

  .collaborativeoffice .productSpect .card {
    min-height: 300px !important;
  }

  .bigdatasolution .operatchanges .card,
  .intelligentsolution .operatchanges .card {
    height: 300px !important;
  }

  .intelligentsolution .waysAdvantage .card {
    height: 350px !important;
  }

  .collaborators .partnersIntroducte .card {
    height: 210px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1360px) {
  .container {
    width: 980px;
    margin: 0 auto;
  }

  .overview .container_title,
  .container_title {
    width: 1180px;
    margin: 0 auto;
  }

  .cando .card,
  .aboutUscon .card {
    min-height: 370px !important;
  }
}

@media screen and (min-width: 1360px) {
  .container {
    width: 1200px;
    margin: 0 auto;
  }

  .overview .container_title,
  .container_title {
    width: 1300px;
    margin: 0 auto;
  }
}
</style>
