<template>
    <div class="bigdatasolution">
        <div class="banner">
            <div class="solutionText">
                <h2 class="animate__animated animate__slideInLeft">大数据分析解决方案</h2>
                <div class="animate__animated animate__slideInRight">
                    <h3>提高业务决策的准确性和效率</h3>
                    <p>汇聚了“指标梳理、数据采集、融合治理、分析应用、数据服务”的全环节、体系化数据资源解决方案。</p>
                </div>
            </div>
        </div>

        <div class="container padUpDown">
            <div class="pro_title">
                <span class="chName">行业趋势</span>
            </div>
            <div class="industry  animate__animated animate__fadeInUp">
                <el-row :gutter="20" type="flex" justify="space-between" align="middle">
                    <el-col :xs="24" :sm="6">
                        <div class="imgbox"> <img alt="" src="@/assets/images/solution/industry4.jpg"></div>
                    </el-col>
                    <el-col :xs="24" :sm="6">
                        <p>日前，在复杂的社会形势、激烈的竞争下，中大型企业可以利用大数据分析在复杂的社会形势和激烈的竞争中更好地理解市场、识别机会、优化运营并提高竞争力。</p>
                    </el-col>
                    <el-col :xs="24" :sm="12">
                        <ul>
                            <li>大数据分析将成为企业决策的重要驱动力：随着数据量的不断增加和处理技术的进步，大数据分析将在企业决策中扮演越来越重要的角色。通过对大量数据的分析和挖掘，企业可以获得更深入的洞察，从而做出更明智的决策。
                            </li>
                            <li>实时分析的需求增长：随着物联网、社交媒体和其他实时数据源的普及，实时分析的需求也在不断增长。企业需要实时监测和处理数据，以便快速响应市场变化和顾客需求。</li>
                            <li>更注重数据安全和隐私保护：随着数据泄露和隐私问题的不断增加，数据安全和隐私保护将成为大数据分析的重要关注点。企业需要采取更多的安全措施，以确保数据的安全性和隐私性。</li>
                            <li>人工智能和机器学习的应用：人工智能和机器学习技术在大数据分析中的应用正在不断增加。这些技术可以帮助企业更好地识别数据中的模式和趋势，并提高预测的准确性。</li>
                            <li>数据可视化和解释的重要性：随着大数据的复杂性和量的增加，数据可视化和解释变得越来越重要。通过将数据以易于理解的方式呈现，企业可以提高员工对数据的理解和利用效率。</li>
                            <li>跨行业的数据合作：随着不同行业的数据量的增加，跨行业的数据合作将成为一种趋势。例如，医疗行业可以和保险行业合作，通过共享数据来提高风险评估的准确性。</li>
                        </ul>
                    </el-col>
                </el-row>
            </div>

        </div>
        <div class="operatchanges padUpDown">
            <div class="pro_title">
                <span class="chName">业务挑战</span>
            </div>
            <div class="container">
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in operateData" :key="index">
                        <el-card shadow="hover" class="card">
                            <div class="imgbox"> <i :class="['iconfont', item.icon]"></i> </div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.desc }}
                            </p>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="prescription padUpDown">
            <div class="pro_title">
                <span class="chName">解决方案</span>
            </div>
            <div class="container">
                <div class="bigdataview">
                    <el-tabs v-model="activeName1" type="card">
                        <el-tab-pane label="多领域指标体系" name="first">
                            <el-row :gutter="20" type="flex" justify="start" align="middle"
                                class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="imgbox">
                                        <img src="@/assets/images/solution/tab1.png" alt="">
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="多模式采集融合" name="second">
                            <el-row :gutter="20" type="flex" justify="start" align="middle"
                                class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="imgbox">
                                        <img src="@/assets/images/solution/tab2.png" alt="">
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="多维度数据展示" name="third">
                            <el-row :gutter="20" type="flex" justify="start" align="middle"
                                class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="imgbox">
                                        <img src="@/assets/images/solution/tab3.png" alt="">
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="多类型数据服务" name="fourth">
                            <el-row :gutter="20" type="flex" justify="start" align="middle"
                                class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="tyspe_item">
                                    <div class="imgbox">
                                        <p>提供内置完善的数据资源服务目录，能够对外部提供高质量的指标数据服务、业务数据服务、数据目录服务、分析结果服务等。
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <div class="waysAdvantage padUpDown">
            <div class="pro_title">
                <span class="chName">方案优势</span>
            </div>
            <div class=" container">
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                        <div class="card">
                            <div class="imgbox">
                                <img alt="" :src="require(`@/assets/images/solution/way${index + 1}.jpg`)">
                            </div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.desc }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "bigdatasolution",
    data() {
        return {
            activeName1: 'first',
            valueDatas: [
                { title: '以价值为导向', desc: '精准抓住客户分析诉求，导师级引导客户并挖掘分析需求，以价值为导向，明确需求。' },
                { title: '以落地为原则', desc: '50+圆周数科掌控一套易落地的实施方法论，独特引导方式精准需求，以DEMO为实际需求确认，与客户保持同一认知标准，所见即所得。' },
                { title: '数据反哺业务', desc: '数字化与信息化最大的区别在于数字化强调的是如何利用数据反哺业务，以分析诉求为蓝本，指导业务系统建设应用。' },
                { title: '体系赋能', desc: '辅助企业建立大数据分析技术框架、指标库、模型规范、分析人才培育等体系建设。' },
            ],

            operateData: [
                { title: '数据质量和治理', icon: 'icon-02', desc: '业务挑战数据质量和治理大数据分析的基础是数据，数据的质量和准确性对分析结果的影响至关重要。然而，在实践中，数据往往存在缺失、错误或是不一致的问题，这需要投入大量的时间和资源进行数据清洗和治理。' },
                { title: '技术复杂性和人才短期', icon: 'icon-rencaifuwu', desc: '大数据分析需要高度的技术能力和专业知识的支持，但是，这种人才在市场上相对短缺。此外，处理大数据需要复杂的技术和工具，这增加了学习和掌握的难度。' },
                { title: '数据访问和集成', icon: 'icon-supervision-full', desc: '在大数据时代，数据的规模和多样性都在不断增加，如何有效地访问和集成这些数据成为了一个重要的挑战。此外，由于数据分布在不同的系统和平台中，跨部门的数据整合和分析也面临着一定的困难。' },
                { title: '数据安全和隐私保护', icon: 'icon-anquanfuben', desc: '随着大数据的普及，数据安全和隐私保护成为了一个重要的挑战。数据泄露、滥用和侵犯个人隐私的问题不断发生，这要求企业采取更加严格的安全措施，以保护数据的安全和隐私。' },
            ]
        };

    },
};
</script>
  
<style lang="scss" scoped>
.bigdatasolution {
    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/solution/banners.jpg") no-repeat;
        background-size: cover;
        position: relative;
    }

    .pro_title {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        text-align: center;
        margin-bottom: 30px;

        span {
            display: block;
        }

        .chName {
            font-size: 26px;
            font-weight: bolder;
            color: #333;
            margin-bottom: 20px;
        }
    }

    .industry {
        img {
            width: 100%;
        }

        ul li {
            font-size: 14px;
            color: #333;
            line-height: 30px;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 20px;
            text-indent: 2em;
            padding: 0 20px;
            color: #333;
        }

    }

    //业务挑战
    .operatchanges {
        background: #f5f5f5;

        .card {
            height: 260px;
            text-align: center;
            border: unset;
            background-color: unset;
            padding: 20px 0;

            .imgbox {
                width: 100px;
                margin: 0 auto;

                i {
                    font-size: 60px;
                    color: #f9b800;
                }
            }

            h3 {
                color: #333;
                font-size: 17px;
                margin-bottom: 10px;
                margin-top: 15px;
            }


            p {
                text-align: left;
                font-size: 14px;
                line-height: 20px;
            }

        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
        }
    }

    //解决方案
    .prescription {
        background: url("@/assets/images/solution/waybanner2.jpg") no-repeat;
        background-size: cover;
        color: #fff;

        .pro_title .chName {
            color: #fff;
        }
    }

    .waysAdvantage {
        background-color: #f7f7f7;

        .card {
            height: 320px;
            text-align: center;
            -webkit-transition: all 0.5s linear;
            transition: all 0.5s linear;
            background: #fff;
            margin-bottom: 10px;

            .imgbox {

                width: 100%;
                height: 140px;
                overflow: hidden;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    transition: All 0.4s ease-in-out;
                    -webkit-transition: All 0.4s ease-in-out;
                    -moz-transition: All 0.4s ease-in-out;
                    -o-transition: All 0.4s ease-in-out;
                }
            }

            h3 {
                color: #333;
                font-size: 17px;
                margin: 30px 20px 10px;

            }

            p {
                font-size: 12px;
                line-height: 20px;
                margin: 0 20px;
            }
        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);

            img {
                transform: scale(1.2);

            }
        }
    }



}
</style>
  