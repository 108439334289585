<template>
    <div class="bigdata">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">大数据</h2>
        </div>
        <div class="productlines padUpDown ">
           <!--  <div class="container">
                <breadcrumb text="大数据" />
            </div> -->
            <el-tabs v-model="activeName">
                <el-tab-pane label="BI商务智能" name="first">
                    <div class="BIIntelligence">
                        <h2>一站式数据分析产品体系</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>


                            <div class="container padUpDown">
                                <p class="overviewdesc"> 提供全能型数据分析解决方案，打通数据处理、数据建模、数据分析全链路，轻松应对企业复杂的全场景分析需求及个性化分析需求。</p>
                                <div class="biOverview">
                                    <el-tabs v-model="activeName1" type="card">
                                        <el-tab-pane label="报表决策分析" name="first">
                                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="8" class="type_item">
                                                    <div class="card">
                                                        <h3>多源数据链接</h3>
                                                        <p>多源数据链接，助力企业打通数据孤岛，让更多数据应用于经营分析和业务管控</p>
                                                        <h3>报表分析</h3>
                                                        <p>通过参数配置实现报表查询、数据过滤，以及联动、钻取分析</p>
                                                        <h3>决策报表</h3>
                                                        <p>组件化拖拽设计，专用于设计可视化程度较高的dashboard驾驶舱、大屏和移动端报表</p>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/BI1.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="自助式BI分析" name="second">
                                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="8" class="type_item">
                                                    <div class="card">
                                                        <h3>灵活处理数据表</h3>
                                                        <p>通过自助数据集功能，普通业务人员就能对数据进行筛选、切割、排序等操作</p>
                                                        <h3>探索式分析数据</h3>
                                                        <p>通过分析看板，发现数据背后隐藏的问题，及时应对业务风险，发现增长点</p>
                                                        <h3>超大数据量分析</h3>
                                                        <p>采用先进的数据引擎，具备高效计算能力和强大的数据压缩能力，支撑前端快速数据分析</p>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/BI2.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="大屏可视化分析" name="third">
                                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="8" class="type_item">
                                                    <div class="card">
                                                        <h3>高效灵活做大屏</h3>
                                                        <p>零代码开发，拖拽布局，点选式配置；编辑过程“所见即所得“，5min快速绘制大屏蓝图</p>
                                                        <h3>一键生成3D城市</h3>
                                                        <p>支持导入geojson，无需建模，自动生成三维建筑；配置数据图层、交互联动，打造3D数字城市</p>
                                                        <h3>海量业务大屏模板</h3>
                                                        <p>内置丰富的可视化组件，以及213+通用业务大屏模板，直接复用效率更高</p>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/BI3.png" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="多终端智能分析" name="fourth">
                                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="8" class="type_item">
                                                    <div class="card">
                                                        <h3>移动数据分析平台</h3>
                                                        <p>全面的移动数据分析功能，媲美PC端的功能体验，另有移动设备的独享优势</p>
                                                        <h3>企业微信/钉钉集成</h3>
                                                        <p>支持与微信和钉钉集成，应用内体验消息推送，报表查看与分析操作，无缝连接</p>
                                                        <h3>个性化定制</h3>
                                                        <p>可在云端可视化的一站式OEM平台，定制个性专属数据分析APP</p>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/BI4.png" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>


                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="keyValue container padUpDown">
                                <el-row :gutter="20" class="type_wrap">
                                    <el-col :xs="24" :sm="6"  class="type_item" v-for="(item, index) in valueDatas" :key="index">
                                        <div class="card">
                                            <div class="imgbox"> <img alt=""
                                                    :src="require(`@/assets/images/productlines/bivalue${index + 1}.png`)">
                                            </div>
                                            <h3>{{ item.title }}</h3>
                                            <p>{{ item.desc }}</p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</template>
  
<script>
import breadcrumb from '@/components/breadcrumb.vue'
export default {
    name: "bigdata",
    components: { breadcrumb },
    data() {
        return {
            activeName: 'first',
            activeName1: 'first',
            valueDatas: [
                { title: '打通数据壁垒', desc: '统一数据口径标准，通过搭建大数据平台实现交通运输企业数据的自动采集、集中汇总，形成企业数据资产池。' },
                { title: '数据灵活补录', desc: '传统企业中存在大量线下手工数据，数字化平台可将手工数据快速制作成模板供员工导入并留存到数据仓库，提升线上化水平。' },
                { title: '提高填报效率', desc: '将日常的巡查、养护等工作所需填报的各种纸质表格移动化，通过扫码定位等方式提高采集效率，提高数据准确性。' },
                { title: '数据多维展示', desc: '提供固定式报表、可视化图表、大屏驾驶舱等多种展示形式，为领导提供决策支持及管理协作。' },
                { title: '跨部门间协作', desc: '建立面向各职能部门的数据平台，实现公司各部门以及各层级之间的信息共享、业务分析决策、协同办公的业务诉求。' },
                { title: '透明业务流程', desc: '通过汇集并抓取客户需求、目标计划、项目实施、账务报告等信息，实现对各业务全流程可视化跟踪，提高管理水平。' },
                { title: '形成体系化分析', desc: '搭建业务数据分析指标体系，形成各行业、集团、企业、业务单元的数字化管理驾驶舱体系，辅助企业数字化管理及决策。' },
                { title: '异常预警推送', desc: '梳理企业各业务流程节点，通过设计预警机制，平台自动将异常信息及时推送给相关责任人，及时采取应对措施。' },
            ],


        };
    },
    created() { },
    methods: {},

};
</script>

  
<style lang="scss" scoped>
.bigdata {
    height: 100%;

    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/productlines/productlines3.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .productlines {

        /* 低代码开发平台 */
        .BIIntelligence {
            h2 {
                font-size: 24px;
                color: #04aaeb;
                text-align: center;
                font-weight: 600;
                letter-spacing: 5px;
                margin: 30px auto;
            }

            .overview {
                .title {

                    background: #d3dce6;

                    .container_title {

                        margin: 0 auto;
                        height: 60px;
                        line-height: 60px;
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                    }
                }


                .keyValue {
                    .card {
                        min-height: 240px;
                        margin-bottom: 20px;
                        text-align: center;
                        padding: 20px;
                        box-shadow: 0px 5px 25px 0px rgba(195, 195, 195, 0.2);
                        -webkit-transition: all 0.5s linear;
                        transition: all 0.5s linear;

                        .imgbox {

                            height: 80px;
                            width: 80px;
                            margin: 0 auto;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;

                            }
                        }

                        h3 {
                            color: #333;
                            font-size: 18px;
                            margin: 20px 0 10px;
                        }

                        p {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .overviewdesc {
                    margin-bottom: 50px;
                }


            }
        }
    }


}
</style>
  