<template>
    <div class="smartmanufacturing">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">智能制造</h2>
        </div>
        <div class="productlines padUpDown " id="tabsScroll">
            <!--     <div class="container">
                <breadcrumb text="智能制造" />
            </div> -->
            <el-tabs v-model="activeName" :class="{ isScroll: scroll }" @tab-click="handleClick">
                <el-tab-pane label="MES生产执行系统" name="first">
                    <div class="lowCode">
                        <h2>生产过程的精细管控</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    MES系统支持离散型业务和流程型业务，满足企业生产过程的精细化管控。支持平板、PAD、手机等多种终端，关联人员、设备、物料等信息，实现投入产出数据的及时反馈、产品质量的有效追溯、更精准的成本管控、生产过程的透明化。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/wesdesc.png" alt="">
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品特点</div>
                            </div>
                            <div class="container productValue padUpDown">
                                <h2 class="center_title">离散型业务</h2>
                                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="12" class="type_item" v-for="(items, index) in lisanOperates"
                                        :key="index">
                                        <el-row :gutter="20" class="card">
                                            <el-col :span="1" class="imgbox">
                                                <i :class="[items.icon, 'iconfont']"></i>
                                            </el-col>
                                            <el-col :span="23" class="personalInfo">
                                                <h3>{{ items.title }}</h3>
                                                <ul>
                                                    <li v-for="(item, index) in items.child" :key="index">
                                                        {{ item }}
                                                    </li>
                                                </ul>

                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                                <h2 class="center_title">流程型业务</h2>
                                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="12" class="type_item" v-for="(items, index) in processOperates"
                                        :key="index">
                                        <el-row :gutter="20" class="card">
                                            <el-col :span="1" class="imgbox">
                                                <i :class="[items.icon, 'iconfont']"></i>
                                            </el-col>
                                            <el-col :span="23" class="personalInfo">
                                                <h3>{{ items.title }}</h3>
                                                <ul>
                                                    <li v-for="(item, index) in items.child" :key="index">
                                                        {{ item }}
                                                    </li>
                                                </ul>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="keyValue container padUpDown">
                                <el-row :gutter="20" type="flex" justify="center"
                                    class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="6" class="type_item">
                                        <ul class="card">
                                            <li>生产效率提升</li>
                                            <li>降低产品不良率</li>
                                            <li>提升设备综合OEE</li>
                                            <li>实现质量的全程追溯</li>
                                        </ul>
                                    </el-col>
                                    <el-col :xs="24" :sm="5" class="type_item">
                                        <div class="card">
                                            <i class="iconfont icon-zhuzhuangtu"></i>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="9" class="type_item">
                                        <ul class="card">
                                            <li>实现安全生产管理的智能化</li>
                                            <li>实现生产现场管理的可视化、透明化</li>
                                            <li>降低在制品库存占用</li>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="WMS仓储管理系统" name="second">
                    <div class="lowCode">
                        <h2>生产物流的智能化管控</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    仓储管理系统WMS充分利用条形码、RFID等技术，帮助企业提高大库和线边库数据的准确性和及时性，实现生产物流的智能化管控。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/wmsdesc.png" alt="">
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container ">
                                <div class="masterdataSpecific padUpDown">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="12" :sm="6" class="type_item" v-for="(item, index) in productTags"
                                            :key="index">
                                            <div class="card wmscard">
                                                <div class="imgbox">
                                                    <i :class="['iconfont', item.icon]"></i>
                                                </div>
                                                <h3>{{ item.title }}</h3>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="container keyValue padUpDown">
                                <el-row :gutter="20" type="flex" justify="center" align="middle"
                                    class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="8" class="type_item">
                                        <ul class="card">
                                            <li>提高收货效率</li>
                                            <li>提高配送效率</li>
                                            <li>提高盘点效率</li>
                                            <li>提升库存周转率</li>
                                            <li>降低库存成本</li>
                                        </ul>
                                    </el-col>
                                    <el-col :xs="24" :sm="6" class="type_item">
                                        <div class="card">
                                            <i class="iconfont icon-weibiaoti--"></i>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="8" class="type_item">
                                        <ul class="card">
                                            <li>货位管理</li>
                                            <li>批次管理</li>
                                            <li>先进先出</li>
                                            <li>集成AGV、WCS系统</li>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="APS智能排程" name="third">
                    <div class="lowCode">
                        <h2>高级计划与排程系统</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container padUpDown">
                                <p class="overviewdesc">
                                    高级计划与排程系统针对不同的行业和业务模式都有相应的的解决方案，尤其适用于小批量、多品种的生产模式。通过与ERP、LIMS、MES、WMS等系统的无缝集成，有效链接全业务价值流。运用多种先进排产算法，平衡制造BOM、设备、人员、交货期、在途订单、在产订单、库存、产能等多重因素，通过两级排程，实现快速有效地响应客户需求。
                                    包含:销售订单、销售预测、主生产计划、工厂AP、车间AS、虚拟排产、可视化排程、计划分析等模块。
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/apsdesc.png" alt="">
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container ">
                                <div class="productValue padUpDown">
                                    <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                        <el-col :xs="24" :sm="12" class="type_item"
                                            v-for="(items, index) in apslisanOperates" :key="index">
                                            <el-row :gutter="20" class="card apscard">
                                                <el-col :span="1" class="imgbox">
                                                    <i :class="[items.icon, 'iconfont']"></i>
                                                </el-col>
                                                <el-col :span="23" class="personalInfo">
                                                    <h3>{{ items.title }}</h3>
                                                    <ul>
                                                        <li v-for="(item, index) in items.child" :key="index">
                                                            {{ item }}
                                                        </li>
                                                    </ul>

                                                </el-col>
                                            </el-row>
                                        </el-col>
                                        <el-col :xs="24" :sm="12">
                                            <img src="@/assets/images/productlines/prospec.png" width="100%">
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                            <div class="title">
                                <div class="container_title">关键价值</div>
                            </div>
                            <div class="container keyValue padUpDown">
                                <el-row :gutter="20" type="flex" justify="center" align="middle"
                                    class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="9" class="type_item">
                                        <ul class="card">
                                            <li>实现对客户订单的交期承诺</li>
                                            <li>生产计划和排产整体效率提升</li>
                                            <li>关键设备OEE提升</li>
                                        </ul>
                                    </el-col>
                                    <el-col :xs="24" :sm="6" class="type_item">
                                        <div class="card">
                                            <i class="iconfont icon-edit3"></i>
                                        </div>
                                    </el-col>
                                    <el-col :xs="24" :sm="6" class="type_item">
                                        <ul class="card">
                                            <li>综合生产效率提升</li>
                                            <li>库存周转率提高</li>
                                            <li>在制品成本降低</li>
                                        </ul>
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="工业仿真" name="fourth">
                    <div class="lowCode">
                        <h2>一体化的数字化工业仿真平台</h2>
                        <div class="overview">
                            <div class="title">
                                <div class="container_title">概 述</div>
                            </div>
                            <div class="container masterdataSpecific padUpDown">
                                <p class="overviewdesc">
                                    工业仿真就是对实体工业的一种虚拟，将实体工业中的各个模块转化成数据整合到一个虚拟的体系中去，在这个体系中模拟实现工业作业中的每一项工作和流程，并与之实现各种交互
                                    圆周数科拥有集3D工艺仿真、装配仿真、人机协作等功能于一体的数字化工业仿真平台。可应用于新建工厂的产线布局设计、物流规划、价值流分析；工厂生产效率提升、精益改善;新产品研发端的可制造性分析、机器人轨迹规划及示教等
                                </p>
                                <div class="imgDesc">
                                    <img src="@/assets/images/productlines/Industrealdesc.jpg" alt="">
                                </div>
                                <h2 class="center_title">痛点</h2>
                                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                                    <el-col :xs="24" :sm="8" class="type_item" v-for="(item, index) in difficulty"
                                        :key="index">
                                        <div class="card painpoint">
                                            <div class="imgbox">
                                                <i :class="['iconfont', item.icon]"></i>
                                            </div>
                                            <p>{{ item.title }}</p>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                            <div class="title">
                                <div class="container_title">产品特性</div>
                            </div>
                            <div class="container produceDesc padUpDown">
                                <el-row :gutter="20" type="flex" justify="space-between" style=" flex-wrap: wrap;">
                                    <el-col :xs="24" :sm="12" v-for="(item, index) in culturesData" :key="index">
                                        <el-row :gutter="20" class="type_wrap">
                                            <el-col :xs="24" :sm="7" class="card">
                                                <div class="imgbox">
                                                    <img alt=""
                                                        :src="require(`@/assets/images/productlines/product${index + 1}.png`)">
                                                </div>
                                            </el-col>
                                            <el-col :xs="24" :sm="16">
                                                <div class="contbox">
                                                    <h3>{{ item.title }} <span>{{ item.icon }}</span></h3>
                                                    <ul>
                                                        <li>{{ item.desc }}</li>
                                                    </ul>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </div>
                            <div class="title">
                                <div class="container_title">产品价值</div>
                            </div>
                            <div class="container ">
                                <div class=" biOverview padUpDown" style="padding-top: 50px;">
                                    <el-tabs v-model="activeName1" type="card">
                                        <el-tab-pane label="3D工业设计" name="first">
                                            <el-row :gutter="20" type="flex" justify="space-around" align="middle"
                                                class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="8" class="type_item">
                                                    <div class="card">
                                                        <h3>新设备入厂方案3D可行性分析、整厂全数据链精益评估、3D并行快速优化设计、布局方案验证落地。</h3>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/sence1.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="虚拟调试与离线编程" name="second">
                                            <el-row :gutter="20" type="flex" justify="space-around" align="middle"
                                                class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="6" class="type_item">
                                                    <div class="card">
                                                        <h3>支持采用OPC
                                                            UA、S7等通讯协议，实现3D模型与软PLC、硬件PLC、PLC仿真器的信号互联互通，进行虚拟电控调试；可实现与市面上99%设备的通讯连接。
                                                        </h3>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/banner.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="物流仿真" name="third">
                                            <el-row :gutter="20" type="flex" justify="space-around" align="middle"
                                                class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="6" class="type_item">
                                                    <div class="card">
                                                        <h3>从进场到出场的全面拉通、物流路径智能规划；物流死锁、交叉、拥堵的消除优化；AGV、牵引车、输送带、人工运输的协同物流</h3>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/sence2.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                        <el-tab-pane label="人机工程仿真" name="fourth">
                                            <el-row :gutter="20" type="flex" justify="space-around" align="middle"
                                                class="type_wrap animate__animated animate__fadeInRight">
                                                <el-col :xs="24" :sm="6" class="type_item">
                                                    <div class="card">
                                                        <h3>通过仿真对关键工艺进行装配仿真分析、人机工程分析、装配过程运动学分析、最终自动输出3D-SOP。</h3>
                                                    </div>
                                                </el-col>
                                                <el-col :xs="24" :sm="16" class="type_item">
                                                    <div class="imgbox">
                                                        <img src="@/assets/images/productlines/productlines5.jpg" alt="">
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>

import breadcrumb from '@/components/breadcrumb.vue'

export default {
    name: "smartmanufacturing",
    components: { breadcrumb },
    data() {
        return {
            activeName: 'first',
            activeName1: 'first',
            scroll: false,
            lisanOperates: [
                { title: '生产作业数字化', icon: 'icon--shengchanzuoye', child: ['生产工单基于生产计划自动生成，并传送至MES终端系统自动接收生产工单','通过MES系统终端，可查询图纸、工艺标准等技术文件及物料清单(BOM)等信息','开工、投料、报工、返工数字化、无纸化','全程条码和RFID卡跟踪管控','具备采集接口的过程数据自动采集'] },
                { title: '过程质量可追溯', icon: 'icon-jieruliucheng', child: ['实现生产过程首检、自检、巡检、成品检数字化','实现按序列号、批次号、箱号等方式的生产数据的实时跟踪','自动采集设备工艺参数，实现在线自动检测、报警和诊断分析生产过程的质量数据实时更新，统计过程控制(SPC)自动生成','实现正向追溯和反向追溯，快速反馈订单、报工、批次号、工作中心、设备、人员等生产信息'] },
                { title: '物流配送智能化', icon: 'icon-wuliuqiache', child: ['基于条形码、无线射频识别(RFID)等识别技术实现仓储配送与生产计划、制造执行以及AGV等系统的集成','基于生产线实际生产情况拉动物料配送，根据客户和产品需求调整目标库存水平']},
                { title: '生产管理可视化', icon: 'icon-supervision-full', child: ['实时呈现包含生产状况(生产数、生产效率、订单总数、完成率)、品质状况(生产数中的不良数、不良率)、设备状况等生产数据，通过各种报表、图表形式展示，直观有效地反映生产状况及品质状况'] },
            ],
            apslisanOperates: [
                { title: '订单承诺', icon: 'icon-shengchanjihualeixing', child: ['问题：无法快速回答交期','解决：通过虚拟排程，快速回复客户交期，实现订单承诺，提升对客户的响应能力']},
                { title: '高效的生产计划', icon: 'icon-kucunguanli', child: ['问题：库存、在产订单、采购等问题需人工协调，排产效率低','解决：考虑全方位的排产因素，快速高效得出排产结果，自动下达到机台的可执行的生产工单'] },
                { title: '指导采购', icon: 'icon-cubencaigouhetong', child: ['问题:：采购随意性大，与生产脱节','解决：通过APS排程，自动生成采购计划指导采购，与生产紧密关联，降低来料库存占用'] },
                { title: '插单', icon: 'icon-edit3', child: ['问题：订单频繁变动，插单困难','解决：多种解决方案应对不同插单业务模式，通过高效的运算，实现快速插单']},
                { title: '集团化管控', icon: 'icon-gaojipeizhicopy', child: ['问题：多公司、多工厂的协调排产难以平衡','解决:：考虑集团化的方案，整合业务的上下游实现集团化的计划管控模式'] },
            ],
            processOperates: [
                { title: '生产作业数字化', icon: 'icon-shouzhi', child: ['工单到线体和机台，自动采集或根据模型计算报工数量和投料数量','与SCADA系统全面集成，自动生成企业所需要的生产、库存、质量等相关报表','生产现场数据透明化、可视化'] },
                { title: '过程质量可追溯', icon: 'icon-yiliaozhiliangfenxi', child: ['实时监控生产流水线上重要工艺参数、设备状态，可视化显示动态数据','统计过程控制(SPC)自动生成，及时报警预判','与化验设备无缝集成，实现检测数据的自动采集，确保数据的准确性和及时性','订单和批次条码全程跟进，实现质量全程追溯'] },
                { title: '能源资源利用集约化', icon: 'icon-navwuliuzhongxin', child: ['建立能源综合管理监控系统，主要耗能设备实现实时监测与控制','建立能耗预测模型，水、电、气(汽)、煤、油以及物料等消耗实现实时监控、自动分析，实现能源资源的优化调度、平衡预测和有效管理'] },
                { title: '安全生产管理智能化', icon: 'icon-anquanfuben', child: ['实现风险分级管控和隐患排查治理体系、化工过程安全管理、安全生产标准化等内容全要素数字化','建设重大危险源监控、可燃有毒气体检测报警、企业安全风险分区、生产人员在岗信息一体化的数字化管理平台','利用大数据分析，建立企业安全生产的预警模型，提高安全生产的智能化水平'] },
            ],
            productTags: [
                { icon: 'icon-wuliuqiache', title: '主动配送' },
                { icon: 'icon-comment-alt-times', title: '退料入库' },
                { icon: 'icon-fenxiang1', title: '委外发料' },
                { icon: 'icon-icon-yuanzhuti', title: '仓库调拨' },
                { icon: 'icon-tishi', title: '紧急配送' },
                { icon: 'icon-batch-download', title: '委外入库' },
                { icon: 'icon-batch-upload', title: '销售出库' },
                { icon: 'icon-kucunguanli', title: '线边库管理' },
            ],
            difficulty: [
                { icon: 'icon-zidongjiedian', title: '多产品多设备兼容性验证难多机器协作弱' },
                { icon: 'icon-houqiweihuweihuweihuguanli', title: '设备数量多且定位复杂设备状况难监控维护效能低' },
                { icon: 'icon-wuliuqiache', title: '物流路径和现实当中有较大差距如路口等待、空车提前等待KAVI智慧仓储' },
                { icon: 'icon-caizhengbutie', title: '产品生产规划及工艺的开发风险高' },
                { icon: 'icon-ziyuan860', title: '产品及工艺投资验证成本高周期长制造效率低' },
                { icon: 'icon-message', title: '生产要素信息不协同重复及不必要成本投入高资源浪费严重' },
            ],
            culturesData: [
                { title: '丰富的电子组件库', desc: '美擎仿真工业软件的电子组件库中有2700+的组件，用户可以使用这些组件来搭建仿真布局' },
                { title: '支持 2D 模型导入', desc: '用户可以将工厂布局的2D工程图导入仿真环境，并且还可以对其进行自定义设置' },
                { title: '支持 3D 模型导入', desc: '用户可以将3D模型直接导入仿真环境，并且美擎仿真工业软件本身兼容性强，因此支持当前大多数主要文件格式和扩展名' },
                { title: '支持点云模型导入', desc: '用户可以将现场设施的点云模型直接导入仿真环境，并在其中搭建布局' },
                { title: '支持几何实体建模', desc: '用户可在仿真环境中，创建简单的3D几何实体模型，或者修改其它导入的3D文件' },
                { title: '支持非标组件建模', desc: '用户可对非标自定义组件进行组件行为和属性的建模，构建属于自己的个性化组件库' },
                { title: '支持建模向导搭建', desc: '用户可使用可自动配置多种组件类型的预构建向导，更快地在仿真环境对3D模型进行逻辑定义' },
                { title: '支持模型轻量化处理', desc: '用户可使用自动几何模型简化工具，对仿真环境中的模型进行轻量化处理，来提高仿真运行效率' },
                { title: '支持工厂布局搭建', desc: '用户可使用电子组件库中现成模型，利用简单的拖放和即插即用功能，快速搭建工厂布局' },
                { title: '支持工艺流程建模', desc: '支持用户使用简单、便捷和可视化的工作流程，从而定义和管理布局中的产品工艺和流程' },
                { title: 'PLC设备连接', desc: '使用独立于仿真平台的OPC-UA或其他特定的接口，将仿真环境与真实世界的PLC控制系统连接起来' },
                { title: '机器人示教', desc: '用户可使用简单易用的机器人教学工具，对机器人行为、属性进行定义、建模' },
            ],
        };
    },
    mounted() {
        var str = document.getElementById("tabsScroll");
        window.addEventListener("scroll", () => {
            var scrollTop =
                document.documentElement.scrollTop || document.body.scrollTop;
            this.scroll = scrollTop > str.offsetTop;
        });
    },
    methods: {
        handleClick(tab, event) { 
            var el = document.getElementById('tabsScroll');
            window.scroll({
                top: el.offsetTop -50,
                behavior: "smooth" // 平滑过渡
            });
        }
    },
};
</script>
<style lang="scss" scoped>
.smartmanufacturing {
    height: 100%;

    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/productlines/productlines2.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .productlines {

        /* 低代码开发平台 */
        .lowCode {

            h2 {
                font-size: 24px;
                color: #04aaeb;
                text-align: center;
                letter-spacing: 5px;
                margin: 30px auto;
            }
            .overview {
                .title {
                    background: #d3dce6;
                    .container_title {
                        margin: 0 auto;
                        height: 60px;
                        line-height: 60px;
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                    }
                }

                .pro_title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #666;
                    text-align: center;
                    margin-bottom: 30px;

                    span {
                        display: block;
                    }

                    .chName {
                        font-size: 26px;
                        font-weight: bolder;
                        color: #333;
                        margin-bottom: 20px;
                    }
                }

                .productValue {
                    .card {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        min-height: 290px;
                        margin-bottom: 20px;
                        padding: 20px 5px;
                        border: 1px solid #ebeef5;
                        transition: 0.3s;
                        /*  border: 1px solid #777777; */

                        .imgbox {
                            width: 60px;
                            margin-bottom: 15px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                transition: all 0.6s;
                            }

                            i {
                                font-size: 40px;
                                color: #04aaeb;
                            }
                        }

                        .personalInfo {
                            h3 {
                                color: #333;
                                font-size: 20px;
                                line-height: 40px;
                                margin-bottom: 15px;
                            }

                            ul {
                                li {
                                    margin-bottom: 10px;
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }

                        }
                    }
                    .apscard{
                        min-height: 200px;
                    }

                    .card:hover {
                        transform: translate(0, -5px);
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
                    }
                }

                .masterdataSpecific {
                    .card {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 15px;
                        transition: all 0.3s ease-in-out;
                        min-height: 160px;

                        .imgbox {
                            height: 100px;
                            line-height: 100px;

                            .iconfont {
                                font-size: 60px;
                                color: #04aaeb;
                            }

                        }

                        h3 {
                            color: #333;
                            font-size: 16px;
                            margin-bottom: 10px;
                            margin-top: 0;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 600;
                            text-align: center;
                            width: 60%;
                            margin: 0 auto;
                        }


                    }

                    .painpoint {
                        min-height: 200px;
                    }

                    .card:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                    }
                }

                .esbSpecific {
                    .type_wrap {
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;

                        .card {
                            height: 300px;
                            text-align: center;

                            .imgbox {
                                width: 70px;
                                height: 70px;
                                margin: 0 auto;
                                border-radius: 50%;
                                border: 1px solid #04aaeb;
                                text-align: center;
                                line-height: 70px;

                                img {
                                    width: 50%;
                                    display: inline-block;
                                    vertical-align: middle;
                                    transition: All 0.4s ease-in-out;
                                    -webkit-transition: All 0.4s ease-in-out;
                                    -moz-transition: All 0.4s ease-in-out;
                                    -o-transition: All 0.4s ease-in-out;
                                }

                                i {
                                    font-size: 40px;
                                    color: #04aaeb;
                                }
                            }

                            h3 {
                                color: #333;
                                font-size: 16px;
                                margin: 30px auto 10px;
                            }

                            p {
                                font-size: 14px;
                                line-height: 20px;
                            }
                        }

                        .card:hover {
                            -webkit-transform: translate(0, -10px);
                            transform: translate(0, -10px);

                            /*        img {
                                animation: rotate 1s forwards;
                                -webkit-animation: rotate 1s forwards;
                            }
 */
                            .imgbox {
                                border: none;
                                box-shadow: 0px 1px 12px -2px #04aaeb;

                            }
                        }
                    }

                }

                .produceDesc {
                    .type_wrap {
                        margin-bottom: 30px;
                        padding: 20px 10px;
                        margin-left: 5px !important;
                        margin-right: 5px !important;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
                        transition: all .3s ease-in-out;
                        min-height: 170px;

                        .imgbox {
                            width: 100px;
                            height: 100px;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                transition: all 0.6s;
                            }
                        }

                        .contbox {

                            max-height: 160px;

                            h3 {
                                color: #333;
                                font-size: 20px;
                                margin-bottom: 10px;
                                line-height: 40px;
                            }

                            ul {
                                padding-left: 20px;
                            }



                        }
                    }

                    .type_wrap:hover {
                        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                        transform: translate(0, -5px);
                        /*  .imgbox img {

                            transform: scale(1.2);
                        } */
                    }
                }

                .keyValue {
                    .card {
                        width: 80%;

                        li {
                            font-size: 16px;
                            font-weight: bolder;
                            margin-bottom: 10px;
                            color: #4A4A4A;
                            line-height: 25px;
                        }

                        li:hover {
                            color: #f9b800;
                        }

                        .iconfont {
                            font-size: 100px;
                            color: #12ACEE;
                            margin-top: 40px;
                            display: inline-block;
                        }
                    }
                }

                .imgDesc {
                    text-align: center;

                    img {
                        width: 80%;
                    }
                }

                .overviewdesc {
                    margin-bottom: 50px;
                }

            }


        }

        /* 主数据管理平台 */


    }


}
</style>
  