import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const state = () => ({
    // 这里是全局数据保存的地方
    tabActive: null,
});
const mutations = {
    setActive(state, option) {
        state.tabActive = option
    },

};
export default {
    state,
    mutations
};
