<template>
    <div class="collaborators">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">我们的合作伙伴</h2>
        </div>
        <!-- 合作伙伴 -->

        <div class="container">
            <div class="partners padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">共建 共享 全方位服务后台</span>
                    <span class="enName">江苏圆周数字科技有限公司拥有强大的合作伙伴群体，为提供卓越解决方案夯实基础，共享共建拥抱新业态，共同助力企业数智化发展。</span>
                </div>
                <el-row :gutter="20" type="flex" justify="space-around" style="flex-wrap: wrap;">
                    <el-col :xs="11" :sm="6" v-for="index in 12" :key="index">
                        <div class="card">
                            <img src="https://res-static.hc-cdn.cn/cloudbu-site/china/zh-cn/wangxue/header/logo.svg"
                                class="imgSize12" v-if="index == 12">
                            <img :class="`imgSize${index}`"
                                :src="require(`@/assets/images/collaborators/friends${index}.png`)" v-else>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="partnersIntroducte padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">合作伙伴简介</span>
                    <!--  <span class="enName">拥有顶尖解决方案合作伙伴，普元信息、每刻科技、阿里云、华为、用友、伟仕佳杰、契约锁、263邮箱、致远等战略合作伙伴。</span> -->
                </div>
                <el-row :gutter="20" class="type_wrap">
                    <el-col ::xs="24" :sm="12" class="type_item" v-for="(item, index) in introducteDatas" :key="index">
                        <div class="card">
                            <div class="imgbox">
                                <img :class="`img${index + 1}`" alt=""
                                    :src="require(`@/assets/images/collaborators/friends${index + 1}.png`)"
                                    v-if="index != 11">
                                <img :class="`img${index + 1}`"
                                    src="https://res-static.hc-cdn.cn/cloudbu-site/china/zh-cn/wangxue/header/logo.svg"
                                    v-else>
                            </div>
                            <p>
                                {{ item.desc }}
                            </p>
                            <div class="desc">
                                <h2>{{ item.name }}</h2>
                                <h3>{{ item.text }}</h3>
                            </div>

                        </div>
                    </el-col>
                </el-row>
            </div>

        </div>
    </div>
</template>
  
<script>
export default {
    name: "collaborators",
    data() {
        return {
            introducteDatas: [
                { name: "普元信息", text: '解决方案合作伙伴', desc: '普元信息技术股份有限公司（股票代码“688118”）是国内软件基础平台（中间件）专业提供商，主要为金融、政务、能源、电信、制造业等行业，提供软件基础平台产品及相应技术服务。是国家规划布局内重点软件企业、国家企业技术中心、博士后科研工作站、国家高技术产业化示范工程单位。' },
                { name: "朗通", text: '战略合作伙伴', desc: '朗通科技是数字工厂领域的领先服务商。十多年来，朗通依托产学研平台专注于高端制造业APS、MES、WMS等系统的研发和实践、不断创新，朗通在系统集成、数据采集、工业互联网平台建设、人工智能应用等方面，具有丰富的实战经验和创新能力。' },
                { name: "泛微", text: '战略合作伙伴', desc: '泛微网络科技股份有限公司成立于2001年，总部设立于上海，专注于协同管理软件领域，帮助组织构建统一的数字化运营平台。泛微是“国家规划布局内重点软件企业”，上交所主板上市公司（股票代码：603039）。' },
                { name: "钉钉", text: '战略合作伙伴', desc: '钉钉（Ding Talk）是阿里巴巴集团打造的企业级智能移动办公平台。钉钉将IM即时沟通、钉钉文档、钉闪会、钉盘、Teambition、OA审批、智能人事、钉工牌、工作台深度整合，打造简单、高效、安全、智能的数字化未来工作方式，助力企业的组织数字化和业务数字化，实现企业管理“人、财、物、事、产、供、销、存”的全链路数字化。' },
                { name: "纷享销客", text: '解决方案合作伙伴', desc: '纷享销客（北京易动纷享科技有限责任公司）在全国50余个城市建立营销服务中心。以连接型CRM为特色，连接业务、人、系统，实现以客户为中心，企业内部和上下游业务的高效协作。纷享销客坚持行业化战略，为高科技、现代企业服务、快消、农牧、大制造等行业的大中型企业提供深度行业化的产品、方案和服务，助力企业通过营销、销售、服务全业务链一体化实现持续增长。' },
                { name: "i人事", text: '解决方案合作伙伴', desc: 'i人事（上海利唐信息科技有限公司）以20多年的人力资源服务团队为基础，会同互联网技术精英，打造最懂HR业务的人力资源HR SaaS+aPaaS 双引擎平台。秉承“业人一体战略落地”的核心理念，以“薪酬绩效”为杠杆，满足人力资源数字化管理需求，撬动组织全面提效、赋能HR与员工。' },
                { name: "阿里邮箱", text: '战略合作伙伴', desc: '阿里企业邮箱是阿里巴巴集团基于阿里云专业云技术和飞天平台打造，具备分布式的海量吞吐、弹性计算、资源精准调度，可实现动态热扩展优势特点。阿里企业邮箱从商业化至今，目前拥有客户超百万家企业，每天邮件投递量超过100000000+，帮助中小企业进行数字化业务转型。获得了工信部第一家认证的SAAS服务提供商，并获得了阿里邮箱三级等保证明。' },
                { name: "帆软", text: '解决方案合作伙伴', desc: '帆软软件有限公司（以下简称“帆软”）成立于2006年，是中国专业的大数据BI和分析平台提供商，专注商业智能和数据分析领域，致力于为全球企业提供一站式商业智能解决方案。连续多年中国BI市场占有率第一。' },
                { name: "苏享会", text: '战略合作伙伴', desc: '江苏省IT生态伙伴私享会，简称“苏享会”成立于2019年7月31日，群成员包含了400+厂商，有软硬件、集成行业。联盟成立以来，多次举办过不同主题的人脉活动，本群奉行商机唯一推荐，资源优先对接本群伙伴。苏享会平台已经成为南京地区优质的信息化生态圈。人脉解锁始于面对面的交流，做到相互学习、共同成长，资源共享、合作共赢！' },
                { name: "契约锁", text: '战略合作伙伴', desc: '契约锁是联合数字证书认证中心、权威公证处、知名律协一起全力打造的“数字可信”基础服务平台。产品涵盖“数字身份、电子签章、印章管控以及数据存证”4大产品体系，帮助组织化解数字化转型过程中的风险，为劳动合同、采购合同、招投标文件、销售合同等各类业务文件提供电子签署支持，助力组织打造信的数字化基础，推动业务全程数字化转型。' },
                { name: "阿里云", text: '云经销商', desc: '阿里云，阿里巴巴集团旗下公司，是全球领先的云计算及人工智能科技公司。阿里云致力于以在线公共服务方式，提供安全、可靠的计算和数据处理能力，让计算和人工智能成为普惠科技，打造云生态，为企业提供一站式服务。' },
                { name: "华为", text: '云经销商', desc: '华为云将华为30多年的ICT技术积累和数字化转型经验开放出来，携手客户、伙伴和开发者，深耕数字化，一切皆服务，致力于让云无处不在，让智能无所不及，共建智能世界云底座。' },
            ]
        };

    },
    created() { },
    methods: {},
};
</script>
  
<style lang="scss" scoped>
.collaborators {
    .banner {
        height: 700px;
        background: url("@/assets/images/aboutus/culture4.jpg") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .partners {
        padding: 30px 10px;

        .pro_title {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 30px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;
            }
        }

        .card {
            height: 100px;
            position: relative;

            img {
                width: 100%;
                margin: 0 auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }

            .imgSize7,
            .imgSize5 {
                width: 80%;
            }

            .imgSize4,
            .imgSize9 {
                width: 60%;
            }

            .imgSize2,
            .imgSize10,
            .imgSize11,
            .imgSize12 {
                width: 50%;
            }

            .imgSize3,
            .imgSize6,
            .imgSize8 {
                width: 40%;
            }

        }

        .type_wrap {

            .type_item {
                .card {
                    height: 300px;
                    text-align: center;
                    -webkit-transition: all 0.5s linear;
                    transition: all 0.5s linear;

                    .imgbox {
                        width: 100px;
                        height: 100px;
                        margin: 0 auto;
                        border-radius: 50%;
                        box-shadow: 0px 4px 11px -3px #58ff58;
                        text-align: center;
                        line-height: 100px;

                        img {
                            width: 50%;
                            display: inline-block;
                            vertical-align: middle;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }
                    }

                    h3 {
                        color: #333;
                        font-size: 16px;
                        margin: 30px auto 10px;
                    }

                    p {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }

                .card:hover {
                    -webkit-transform: translate(0, -10px);
                    transform: translate(0, -10px);

                    img {
                        animation: rotate 1s forwards;
                        -webkit-animation: rotate 1s forwards;
                    }
                }
            }

        }
    }

    .partnersIntroducte {

        .pro_title {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;


            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;

                letter-spacing: 5px;
            }
        }

        .type_wrap {

            .type_item {
                padding-top: 30px;
                /*   min-height: 400px; */

                .card {
                    /* height: 300px; */
                    text-align: center;
                    padding: 50px 30px 20px;
                    position: relative;

                    .imgbox {
                        position: absolute;
                        top: -50px;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: 0 auto;
                        width: 200px;
                        height: 100px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 100px;
                        background: #fff;
                        z-index: 9;
                        /* padding: 20px; */
                        overflow: hidden;


                        img {
                            width: 70%;
                            /* border-radius: 50%; */
                            vertical-align: middle;
                            display: inline-block;
                            z-index: 12;
                            position: relative;
                            z-index: -1;
                        }

                        .img7 {
                            width: 100%;
                        }

                        .img1,
                        .img5,
                        .img9 {
                            width: 90%;
                        }

                        .img2,


                        .img8 {
                            width: 60%;
                        }

                        .img3,
                        .img6 {
                            width: 50%;
                        }

                    }

                    .desc {
                        position: absolute;
                        bottom: 5%;
                        text-align: center;
                        left: 45%;

                        h2 {
                            color: #333;
                            font-size: 16px;
                            margin: 10px auto 0;
                        }

                        h3 {
                            color: red;
                            font-size: 12px;
                        }
                    }



                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin-top: 20px;
                        text-align: left;
                    }
                }

                .card:hover {
                    border: 1px solid #04aaeb !important;

                    .imgbox {
                        border: 1px solid #04aaeb !important;
                    }

                }
            }

        }

    }
}
</style>
  