<template>
    <div class="product">
        <div class="banner">
            <h2 class="animate__animated animate__slideInLeft">我们的产品</h2>
        </div>
        <!-- 产品线 -->
        <div class="container">
            <div class="productlines padUpDown ">
                <div class="pro_title">
                    <span class="chName">十五年甲方实战 更注重数智化落地</span>
                    <span class="enName">产品线涵盖低代码开发平台、智能制造、大数据、协同办公、ERP、供应链、云服务及定制服务，一站式解决企业数字化转型的痛点和难点。</span>
                </div>
                <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInUp">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in productDatas" :key="index">
                        <div class="card" @click="goToDtails(index)">
                            <div class="imgbox"> <img alt=""
                                    :src="require(`@/assets/images/productlines/productlines${index + 1}.jpg`)">
                            </div>
                            <h3>{{ item.title }}</h3>
                            <p class="more" v-if="index < 4">了解更多</p>
                            <p v-for="(i, index) in item.child" :key="index">{{ i }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "productlines",
    data() {
        return {
            productDatas: [
                { title: '基础软件', child: ['低代码开发平台', 'ESB企业服务总线', '主数据管理平台', 'BPM流程引擎'] },
                { title: '智能制造', child: ['MES生产执行系统', 'WMS仓储管理系统', 'APS智能排程', '工业仿真'] },
                { title: '大数据', child: ['BI商务智能'] },
                { title: '协同办公', child: ['即时通讯', 'OA智慧办公', '电子签章', '企业邮箱'] },
                { title: 'ERP', child: ['NC', 'U9', 'U8', 'T系列', 'SAP'] },
                { title: '供应链', child: ['智慧营销', '电子采购平台'] },
                { title: '云服务', child: ['华为云', '阿里云'] },
                { title: '定制服务', child: ['信息系统定制开发', '小程序', 'APP', '网站', '公众号'] },
            ],
        };
    },
    created() { },
    methods: {
        goToDtails(index) {
            if (index < 4) {
                this.$router.push({
                    path: index == 0 ? "/basicsoftware" : index == 1 ? '/smartmanufacturing' : index == 2 ? '/bigdata' : 'collaborativeoffice',
                });
            }
        }
    },

};
</script>
  
<style lang="scss" scoped>
.product {
    height: 100%;

    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/productlines/banner.jpg") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }
    }

    //产品线
    .productlines {
        .pro_title {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 30px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
            }
        }

        .type_wrap {

            .type_item {
                .card {
                    height: 400px;
                    text-align: center;
                    width: 100%;
                    margin-bottom: 20px;
                    transition: all .3s ease-in-out;
                    border-radius: 8px;
                    border: 1px solid #f1f1f1;

                    .imgbox {
                        width: 100%;
                        height: 200px;
                        margin-bottom: 30px;
                        overflow: hidden;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: inline-block;
                            vertical-align: middle;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }
                    }

                    h3 {
                        color: #333;
                        font-size: 16px;
                        margin-bottom: 10px;
                        margin-top: 0;
                    }

                    .more {
                        margin-bottom: 5px;
                        color: red;
                        cursor: pointer;
                    }

                    p {
                        font-size: 12px;
                        line-height: 20px;
                        margin: auto 20px;
                    }
                }

                .card:hover {
                    box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
                    transform: translate(0, -5px);

                    img {
                        /*  animation: rotate 1s forwards;
          -webkit-animation: rotate 1s forwards; */
                        transform: scale(1.2);
                    }
                }
            }

        }
    }
}
</style>
  