<template>
    <div class="aboutUs">
        <div class="banner">
            <div class="textbox">
                <h2 class="animate__animated animate__slideInLeft">关于我们</h2>
                <h3 class="animate__animated animate__slideInRight">在圆周里 无限空间</h3>
            </div>
        </div>
        <!-- 关于我们 -->
        <div class="container">
            <div class="aboutUscon padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">圆周数科简介</span>
                    <span
                        class="enName">圆周数科成立于2023年，注册资本金1000万，是一家专业从事企业信息化咨询规划、方案设计、项目实施开发、系统运维的一体化的初创型数字科技公司。公司团队成员目前有原大型集团CIO、互联网公司CEO、互联网公司CTO、互联网公司资深顾问等行业顶端人才，有强大的行业内资源，是一家主打解决方案高度匹配性、可行性、落地性的数字化企业，是一家综合能力和实力均较强的企业。</span>
                </div>
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                        <el-card shadow="hover" class="card">
                            <div class="imgbox">
                                <img alt="" :src="require(`@/assets/images/index/cando${index + 1}.png`)">
                            </div>
                            <div class="textbox">
                                <h3 v-if="index == 0">{{ item.title }} <br>细节周到为“周”</h3>
                                <h3 v-else>{{ item.title }}</h3>
                            </div>
                            <p>{{ item.desc }}</p>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
            <div class="history padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">圆周的背景和文化</span>
                    <span class="enName">圆周来源于圆周率这一串无限循环的数字——π值 <br>古往今来人们没有停止过对π值的探索 我们也将抱着这种使命 不会停下数字化时代研究的脚步</span>
                </div>
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="10" class="type_item">
                        <div class="imgbox">
                            <img alt="" src="@/assets/images/logo/onlylogo.png">
                        </div>

                    </el-col>
                    <el-col :xs="24" :sm="14" class="type_item">
                        <div class="card" v-for="(item, index) in history" :key="index">
                            <div class="textbox">
                                <i :class="[`${item.icon}`, 'iconfont']"></i>
                                <h3>{{ item.title }}</h3>
                            </div>
                            <p>{{ item.desc }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="type_wrap" style="margin-top: 100px;">
                    <el-col :xs="24" :sm="16" class="bossInfo">
                        <h2>创始人 周琦宝</h2>
                        <h3>————从大型集团CIO走向定制服务之路</h3>
                        <ul>
                            <li>15年甲方信息管理工作；</li>
                            <li>曾同时服务10+产业板块，300+家子公司共60余套系统；</li>
                            <li>深度了解甲方及目前中大型企业信息化建设的痛点；</li>
                            <li>经历10+百万级及3个千万级信息项目的选型，从战略、变革到计划、执行、沟通全过程，帮助企业顺利上线、无缝衔接；</li>
                            <li>信息系统和信息资源的规划、整合、运作等方面行业知名资深CIO；</li>
                            <li>镇江市CIO联盟成员/镇江市信息化协会“镇江市优秀CIO”;</li>
                        </ul>
                    </el-col>
                    <el-col :xs="24" :sm="8" class="type_item">
                        <div class="imgbox">
                            <img alt="" src="@/assets/images/aboutus/boss.jpg">
                        </div>

                    </el-col>
                </el-row>
            </div>
            <div class="cultures padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">我们的企业文化</span>
                    <i class="enName">诚信、协同、思维、专注、改善 </i>
                </div>
                <el-row :gutter="20">
                    <el-col :xs="24" :sm="12" v-for="(item, index) in culturesData" :key="index">
                        <el-row :gutter="20" class="type_wrap">
                            <el-col :span="12">
                                <div class="imgbox">
                                    <img alt="" :src="require(`@/assets/images/aboutus/culture${index + 1}.jpg`)">
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="contbox">
                                    <h3>{{ item.title }} <span>{{ item.icon }}</span></h3>
                                    <p>{{ item.desc }}</p>
                                </div>
                            </el-col>
                        </el-row>

                    </el-col>
                </el-row>
            </div>
            <div class="officeEnvironment padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">我们的办公环境和福利</span>
                </div>
                <el-row :gutter="20" class="type_wrap" style="  display: flex; justify-content: space-between;">
                    <el-col :xs="6" :sm="3" class="type_item" v-for="(item, index) in benefits" :key="index">
                        <div class="card" style="height: 100px;">
                            <div class="imgbox">
                                <i :class="[`${item.icon}`, 'iconfont']"></i>
                            </div>
                            <h3>{{ item.title }}</h3>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="index > 2 ? 12 : 8" class="type_item" v-for="(item, index) in 3" :key="index">
                        <div class="card">
                            <div :class="['offerbox', index < 3 ? 'offerbox1' : '']">
                                <img :src="require(`@/assets/images/aboutus/office${index + 1}.jpg`)">
                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="10" class="type_item">
                        <div class="card">
                            <div class="offerbox">
                                <img src="@/assets/images/aboutus/office4.jpg">
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="8" class="type_item">
                        <div class="card">
                            <div class="offerbox">
                                <img src="@/assets/images/aboutus/office5.jpg">
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="6" class="type_item">
                        <div class="card">
                            <div class="offerbox">
                                <img src="@/assets/images/aboutus/office6.jpg">
                            </div>
                        </div>
                    </el-col>

                </el-row>
            </div>
        </div>
        <div class="joinUs padUpDown animate__animated animate__fadeInUp">
            <div class="container">
                <div class="pro_title">
                    <span class="chName">想加入我们吗？</span>
                    <span class="enName" @click="dialogVisible = true">来联系我们吧</span>
                </div>
                <p>这辈子千万不要马虎两件事，一是找对爱人，二是找对事业。因为太阳升起时要投向事业，太阳落山时要与爱人相拥。我们终其一生，都在寻找两样东西，一是价值感，二是归属感……价值感来源于肯定，归属感来源于被爱。在圆周数科，价值感和归属感是我们最重要的信仰。
                </p>
            </div>
        </div>


        <el-dialog title="联系我们" :visible.sync="dialogVisible" center>
            <div class="contactUsCon padUpDown">
                <div class="textDesc">
                    <div class="caveat">
                        <h3> <i class="el-icon-s-home"></i>江苏圆周数字科技有限公司</h3>
                        <p> <i class="el-icon-location-information"> </i>江苏省镇江市润州区茶砚山路106号阿里云创新中心2楼207</p>
                        <p> <i class="el-icon-phone-outline"> </i>08：30-18：30 —— <a href="tel:0511 8560 8166">0511
                                8560 8166</a></p>
                        <p> <i class="el-icon-mobile-phone"> </i>24H 来电 —— <a href="tel:182 6064 3153">182 6064
                                3153</a></p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "aboutUs",
    data() {
        return {
            dialogVisible: false,
            form: {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            valueDatas: [
                { title: '项目闭环为“圆”', desc: '更注重项目的闭环，即流程的无缝衔接和完备，形成一个高效的无死角的运转圈。' },
                { title: '完备的资源保障体系', desc: '整合行业内顶尖解决方案，创造超越企业预期目标的价值。' },
                { title: '强大的技术团队', desc: '公司开发团队成员汇集了原大型数百亿集团CIO、互联网公司CEO、互联网公司CTO、互联网公司资深顾问等行业顶端人才。' },
                { title: '专人实施 确保落地', desc: '精抓痛点、卓越方案、确保落地。' },
            ],
            history: [
                { title: 'LOGO文化', icon: 'icon-a-hexinwenhualiniandingwei', desc: '圆周 来源于圆周率这一串无限循环的数字——π值 古往今来 人们没有停止过对π值的探索 我们也将抱着这种使命 不会停下数字化时代研究的脚步。' },
                { title: '管理文化', icon: 'icon-fuwuguanli', desc: '项目闭环度是圆，管理条理性是周。侧重闭环和细节以及服务的周到，确保企业稳步高效的步入数字化运营。' },
                { title: '圆周=圆方', icon: 'icon-Tireroll', desc: '无方难立人，无圆难成事。在方中做人，在圆中归真。守规矩原则、知方寸底线。' },
            ],
            culturesData: [
                { title: '愿景', icon: 'vision', desc: '成为首选信赖的产业数字化领跑服务商。' },
                { title: '使命', icon: 'mission', desc: '创造超越企业预期目标的价值为其发展赋能，使组织更高效、更智慧。' },
                { title: '服务理念', icon: 'service concept', desc: '项目闭环为"圆"，细节周到为"周"；精抓痛点，卓越方案，确保落地。' },
                { title: '企业文化', icon: 'corporate culture', desc: '以共建共享的视角拥抱新业态；以持续学习的态度营造无限成长空间；以积极上进的活力保持对生活的热爱。' },
            ],
            benefits: [
                { title: '高配写字楼', icon: 'icon-categoryE' },
                { title: '五险一金', icon: 'icon-uniEE' },
                { title: '自助食堂', icon: 'icon-icon10' },
                { title: '节日福利', icon: 'icon-shengriliwu' },
                { title: '员工团建', icon: 'icon-renyuan' },
                { title: '双 休', icon: 'icon-xiuxiri' },
                { title: '晋升规划', icon: 'icon-yuangongjingyingfenxi' },
            ],
            rules: {
                name: [{
                    required: true, message: '姓名不能为空', trigger: 'blur'
                }],
                phone: [{
                    required: true, message: '电话不能为空', trigger: 'blur'
                }]
            }
        };
    },
    created() { },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    },
};
</script>
  
<style lang="scss" scoped>
.aboutUs {
    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/aboutus/banner.webp") no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            color: #fff;
            font-size: 50px;
            letter-spacing: 20px;
        }

        h3 {
            margin-top: 20px;
            font-size: 40px;
            color: #fff;
            letter-spacing: 20px;
        }
    }

    //产品线
    .aboutUscon {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 15px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin: 20px 0;
                letter-spacing: 5px;
            }
        }

        .card {
            /* height: 430px; */
            min-height: 430px;
            text-align: center;
            -webkit-transition: all 0.5s linear;
            transition: all 0.5s linear;

            .imgbox {

                width: 100%;
                /*   height: 240px; */
                overflow: hidden;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    transition: All 0.4s ease-in-out;
                    -webkit-transition: All 0.4s ease-in-out;
                    -moz-transition: All 0.4s ease-in-out;
                    -o-transition: All 0.4s ease-in-out;
                }
            }

            .textbox {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0 0;

                h3 {
                    color: #04aaeb;
                    font-size: 16px;
                }
            }

            p {
                font-size: 12px;
                line-height: 20px;
            }
        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);

            img {
                transform: scale(1.2);

            }
        }
    }

    .history {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 60px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;
            }

            .enName {
                width: 70%;
                margin: 0 auto;
            }
        }

        .type_wrap {
            .bossInfo {
                h2 {
                    text-align: center;
                    font-size: 20px;
                }

                h3 {
                    text-align: center;
                    font-size: 16px;
                    margin: 15px 0;
                }

                ul {
                    padding: 30px 0;

                    li {
                        font-size: 14px;
                        line-height: 30px;
                    }
                }
            }

            .type_item {
                .imgbox {
                    max-width: 300px;
                    margin: 0 auto;

                    img {
                        width: 100%;
                        display: block;
                        border-radius: 10px;
                    }
                }

                .card {
                    margin-top: 30px;
                    -webkit-transition: all 0.5s linear;
                    transition: all 0.5s linear;



                    .textbox {
                        margin-bottom: 10px;
                        display: flex;

                        .iconfont {
                            color: red;
                            font-size: 20px;
                        }

                        h3 {
                            color: #333;
                            font-size: 16px;
                            margin-left: 10px;
                        }
                    }

                    p {
                        font-size: 14px;
                        line-height: 20px;
                        margin-left: 30px;
                    }
                }

                /*  .card:hover {
                    -webkit-transform: translate(0, -10px);
                    transform: translate(0, -10px);

                    img {
                        animation: rotate 1s forwards;
                        -webkit-animation: rotate 1s forwards;
                    }
                } */
            }

        }
    }

    /* 解决方案 */
    .cultures {

        .pro_title {
            font-size: 16px;
            color: #727272;
            text-align: center;
            margin-bottom: 60px;
            letter-spacing: 15px;
            font-weight: 600;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;

            }
        }

        .type_wrap {
            margin-bottom: 30px;
            min-height: 120px;

            .imgbox {
                overflow: hidden;
                height: 200px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    transition: all 0.6s;
                }
            }

            .contbox {
                padding-top: 50px;
                max-height: 200px;

                h3 {
                    color: #333;
                    font-size: 20px;
                    margin: 0 0 20px;

                    span {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }


            }
        }

        .type_wrap:hover {
            .imgbox img {
                /*  animation: rotate 1s forwards;
      -webkit-animation: rotate 1s forwards; */
                transform: scale(1.2);
            }
        }
    }

    .officeEnvironment {
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 60px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
                letter-spacing: 5px;
            }
        }

        .type_wrap {

            .type_item {
                margin-bottom: 20px;

                .card {

                    text-align: center;
                    -webkit-transition: all 0.5s linear;
                    transition: all 0.5s linear;

                    .offerbox {
                        width: 100%;
                        height: 400px;
                        overflow: hidden;
                        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.4);

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }
                    }

                    .offerbox1 {
                        height: unset;
                    }

                    .imgbox {
                        width: 60px;
                        height: 60px;
                        margin: 0 auto;
                        border-radius: 50%;
                        box-shadow: 0px 4px 11px -3px #04aaeb;
                        text-align: center;
                        line-height: 60px;

                        img {
                            width: 50%;
                            display: inline-block;
                            vertical-align: middle;
                            transition: All 0.4s ease-in-out;
                            -webkit-transition: All 0.4s ease-in-out;
                            -moz-transition: All 0.4s ease-in-out;
                            -o-transition: All 0.4s ease-in-out;
                        }

                        .iconfont {
                            font-size: 26px;
                        }
                    }

                    h3 {
                        color: #333;
                        font-size: 16px;
                        margin: 15px auto 10px;
                    }
                }

                .card:hover {
                    img {
                        transform: scale(1.2);
                    }

                    .imgbox {
                        background-color: #04aaeb;
                        transition: All 0.4s ease-in-out;

                        .iconfont {
                            color: #fff;

                        }
                    }

                }
            }

        }
    }

    .joinUs {
        position: relative;

        /*  background: #000;
        color: #fff; */
        /*      background: url("@/assets/images/aboutus/joinus2.jpg") no-repeat 100%; */
        .pro_title {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 40px;

            .chName {
                font-size: 26px;
                font-weight: bolder;
                margin-bottom: 20px;
                letter-spacing: 5px;
                display: block;
            }

            .enName {

                display: block;
                font-style: italic;
                color: red;
                cursor: pointer;
            }
        }

        p {
            line-height: 40px;
            letter-spacing: 2px;
            text-indent: 2em;
        }
    }


    .contactUsCon {
        padding: 0 50px 50px;

        .pro_title {
            font-size: 14px;
            font-weight: 400;
            color: #666;
            text-align: center;
            margin-bottom: 30px;

            span {
                display: block;
            }

            .chName {
                font-size: 26px;
                font-weight: bolder;
                color: #333;
                margin-bottom: 20px;
            }
        }

        .textDesc {
            h2 {
                text-align: center;
            }
            .caveat {
                margin-bottom: 20px;
                border: 1px solid #f5f5f5;
                padding: 10px 20px;

                i {
                    margin-right: 5px;
                    font-size: 20px;
                    color: #04aaeb;
                }

                h3 {
                    font-size: 15px;
                    margin-bottom: 10px;
                    color: #000;
                }

                p {
                    line-height: 30px;
                    font-size: 14px;
                    color: #000;

                    a {
                        text-decoration: none;
                        color: #000;
                    }

                    a:hover {
                        color: #e6a23c;
                    }
                }
            }

        }
    }
}
</style>
  