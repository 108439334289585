<template>
    <div class="mb20">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <!--     <el-breadcrumb-item :to="{ path: '/productlines' }">产品线</el-breadcrumb-item> -->
            <el-breadcrumb-item>{{ text }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>
  
<script>
export default {
    name: "breadcrumb",
    props: ['text'],
    data() {
        return {};
    },
};
</script>
  