import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './assets/css/defaultcss.scss'
import './assets/css/iconfont.css'
import './assets/css/animate.min.css'

import BaiduMap from 'vue-baidu-map'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(BaiduMap, {
  ak: '7r3R3wFlWfIEX7jx1nTwfES58tivmXx5'
})

//解决elementui的dialog弹框时页面会闪的问题
ElementUI.Dialog.props.lockScroll.default = false;
//解决路由切换后, 页面滚动位置不变的问题
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
