<template>
    <div class="joinUs">
        <div class="banner">
            <div class="textbox">
                <h2 class="animate__animated animate__slideInLeft">加入我们</h2>
                <h3 class="animate__animated animate__slideInRight">价值感来源于肯定 归属感来源于被爱</h3>
            </div>
        </div>
        <!-- 联系我们 -->

        <div class="container">
            <div class="contactUsCon padUpDown animate__animated animate__slideInUp">

                <div class="textDesc">
                    <h2>加入我们</h2>
                    <!--  <div class="caveat">
                        <h3> <i class="el-icon-s-home"></i>江苏圆周数字科技有限公司</h3>
                        <p> <i class="el-icon-location-information"> </i>江苏省镇江市润州区茶砚山路106号阿里云创新中心2楼207</p>
                        <p> <i class="el-icon-phone-outline"> </i>08：30-18：30 —— 0511 8560 8166</p>
                        <p> <i class="el-icon-mobile-phone"> </i>24H 来电 —— 182 6064 3153</p>
                    </div> -->
                </div>
                <h3 class="hot"> <i class="icon-icon-test iconfont"></i> 热招职位</h3>

                <!-- MES实施工程师 -->
                <div class="jobList" v-for="(item, index) in workList" :key="index">
                    <div class="jobItem" @click="showItemCon(item)">
                        <span class="jonName">{{ item.job }} <i>【江苏-镇江-润州区】</i><i class="num">{{ item.num
                        }}</i></span>
                        <span class="address"> <i>{{ item.workHours }}</i> <i>本科</i> </span>
                        <div class="tabs">
                            <span v-for="(row, i) in item.tags" :key="i">
                                <i :class="`bkcol${i}`">{{ row }}</i>
                            </span>
                            <el-button type="primary" icon="el-icon-s-comment" size="mini" round class="contactsUsbtn"
                                @click.stop="showDialogVisible = true">立即沟通</el-button>
                        </div>
                    </div>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 0 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责为客户提供专业的MES顾问和实施服务；</li>
                                    <li>负责MES项目前期的客户需求分析，售前解决方案制定；</li>
                                    <li>负责对客户进行项目实施培训并支持后续的实施应用；</li>
                                    <li>负责完成MES系统配置和测试工作；</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>本科及以上学历；</li>
                                    <li>有3年以上MES/ERP/CRM/OA等软件产品销售经验者优先；</li>
                                    <li>了解制造业企业生产工艺流程；</li>
                                    <li>参与或主导过MES车间执行信息系统实施工作，有良好的沟通能力和团队合作能力。</li>
                                    <li>有较强的分析能力和很强的客户沟通能力，能独立解决复杂的技术问题。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 1 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责为客户提供专业的WMS顾问和实施服务；</li>
                                    <li>负责WMS项目前期的客户需求分析，售前解决方案制定；</li>
                                    <li>负责对客户进行项目实施培训并支持后续的实施应用；</li>
                                    <li>负责完成WMS系统配置和测试工作；</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>计算机相关专业，本科学历，28-45岁，3年以上WMS系统实施经验；</li>
                                    <li>熟悉MES/WMS/TMS/OMS/CRM/ERP系统的体系结构和重要相关表结构，</li>
                                    <li>精通SQL、Oracle数据库开发；</li>
                                    <li>有开发经验者优先。</li>
                                    <li>有高度责任心,做事认真负责；吃苦耐劳,能够积极应对及解决项目过程中的各类突发事件；</li>
                                    <li>有仓储管理经验者优先，具备良好的沟通能力和综合分析能力；</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 2 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责为客户提供专业的APS顾问和实施服务；</li>
                                    <li>负责APS项目前期的客户需求分析，售前解决方案制定；</li>
                                    <li>负责对客户进行项目实施培训并支持后续的实施应用；</li>
                                    <li>负责完成APS系统配置和测试工作；</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>计算机相关专业，本科学历，28-45岁，3年以上APS系统实施经验；</li>
                                    <li>熟悉MES/WMS/APS/ERP系统的体系结构和重要相关表结构，</li>
                                    <li>精通SQL、Oracle数据库开发；</li>
                                    <li>有开发经验者优先。</li>
                                    <li>有高度责任心,做事认真负责；吃苦耐劳,能够积极应对及解决项目过程中的各类突发事件；</li>
                                    <li>有生产调度经验者优先，具备良好的沟通能力和综合分析能力；</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 3 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>根据客户业务发展战略目标，规划数字化转型相关业务蓝图，为客户提供业务咨询、规划等专业服务；</li>
                                    <li>能够独立或者与其他顾问协同提供对客户的数字化转型相关咨询服务，包括组织和参与收集客户业务发展战略与数字化转型需求，综合分析并提出独立咨询建议，推动客户数字化变革；
                                    </li>
                                    <li>支持业务团队进行相关项目的售前/售中/售后相关业务咨询；</li>
                                    <li>能够直接面对客户高层进行沟通并提供定制化解决方案；</li>
                                    <li>为客户提供一体化端到端解决方案建议、产品组合建议。</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>5年以上大型咨询机构企业数字化相关咨询服务经验；</li>
                                    <li>5年以上企业客户ToB解决方案相关从业经验；</li>
                                    <li>知识面广阔，对主流企业IT应用有广泛的了解；</li>
                                    <li>优秀的演讲能力，能够生动准确地展示方案的理念、核心内容。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 4 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责离散制造行业智能工厂/数字化车间/智能化生产线规划设计业务，包括项目业务调研、需求分析、方案设计，针对业务需求结合公司产品和行业解决方案进行业务和流程梳理，提供可行性解决方案。
                                    </li>
                                    <li>负责与公司销售人员配合开展需求对接，售前技术支持和售前技术方案设计。</li>
                                    <li>负责智能制造相关项目整体解决方案设计、技术集成和项目管理等工作。</li>
                                    <li>负责根据项目或研究需求开展数据收集、市场调查、数据分析、行业研究、案例分析和标杆研究，形成行业灯塔工厂建设解决方案。</li>
                                    <li>负责重点客户项目的咨询、方案论证、招投标等业务咨询和支持活动。</li>
                                    <li>完成上级领导交办的其他任务。</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>全日制统招本科及以上学历，机械工程、自动化、工业工程等相关专业，工作经验5年以上，其中4年以上智能制造相关项目规划设计实施经验。</li>
                                    <li>具备智能制造解决方案供应商从业背景，掌握离散制造业智能制造整体解决方案和战略规划方法。</li>
                                    <li>具备离散制造行业车间制造执行管理、质量管理、物流管理、设备管理、能源管理等业务集成经验，熟悉相关信息化系统的集成关系。</li>
                                    <li>具备机器人自动化生产线、AGV、自动化立体仓库、生产线管控系统、上位机系统等自动化装备及控制系统应用经验。</li>
                                    <li>熟练使用autoCAD、UG、Soildworks、CATIA等CAD软件开展布局设计和三维建模。</li>
                                    <li>具备优秀的行业解决方案编写能力，较强的PPT呈现能力、演讲能力，较强的客户需求引导能力。</li>
                                    <li>具有良好的沟通能力和团队合作精神，具备较强的执行力和学习能力。</li>
                                    <li>能适应适度的工作出差要求。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 5 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>根据公司战略制定团队销售计划；</li>
                                    <li>负责区域市场的大型企业客户发掘.销售，</li>
                                    <li>帮助销售团队成员明确销售方向.分解团队销售目标任务.制定销售计划并敦促其执行；</li>
                                    <li>负责重大销售合同的谈判和签订；</li>
                                    <li>负责团队的日常管理.组织文化宣贯以及人才队伍建设工作；</li>
                                    <li>通过提供客户需求信息和产品后期信息反馈，协助整合营销制定产品营销策略和市场活动推广方案。</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>本科以上学历，形象气质佳；</li>
                                    <li>四年以上销售管理经验，善于把握人际关系，良好的商务拓展能力及谈判技巧，统筹策划沟通能力强；</li>
                                    <li>在管理咨询领域有相关经验.B2B解决方案销售管理经验优先考虑；</li>
                                    <li>以往销售对象为大型企业，具有较好的大客户开发.维护技巧，具有大客户资源者优先考虑；</li>
                                    <li>结果导向并具备一定的领导能力；</li>
                                    <li>喜欢挑战，能承受高压，能适应短途出差。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 6 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>针对客户需求和行业特点，依据项目经理的建议和指导，在客户实施过程中能准确把握客户需求并能加以实现，并按照实施计划，进行实施部署</li>
                                    <li>在实施过程中，对客户的企业管理工作进行诊断，提出实施计划的合理性建议和解决方案，及时、有效协调解决项目实施过程中出现的疑难问题，并按照实施流程顺利完成实施项目，按时保质实现项目交付和验收，提高客户满意度和用户体验
                                    </li>
                                    <li>按照客户要求协助进行现场培训和指导，进行实施文档的编写，完成项目实施进度报告，实施总结等</li>
                                    <li>与客户方保持良好的合作，确保项目进度与质量</li>
                                    <li>协助上级完成部门其它工作</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>本科及以上学历；3年以上相关工作经验</li>
                                    <li>具有OA、ERP系统实施、维护、培训方面的经验者优先</li>
                                    <li>具备良好的SQLserver、Oracle等数据库的操作和维护知识和维护能力；</li>
                                    <li>能够胜任项目实施方案的撰写、需求分析和建议书的编写；</li>
                                    <li>具备良好自信心，有较强的口头表达能力；</li>
                                    <li>有强烈的进取精神、事业心、责任心，能够在压力下工作。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 7 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责基于客户的业务需求做数据支撑的数据应用开发；</li>
                                    <li>负责基于大数据平台技术的实时数仓和离线数仓的应用落地，如数据采集、清洗、处理、展示、输出等环节；</li>
                                    <li>负责数据仓库设计，根据业务需求实现高效率高灵活性的查询，数据分析，数据挖掘；</li>
                                    <li>协助产研团队负责客户项目价值需求转化为产品能力；</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>5年以上大数据开发经验，4个以上完整项目经验，具备4年及以上数仓ETL相关工作经验，3年以上的大数据架构设计经验，熟练使用Java、Scala、Python三种开发语言，并对至少1种开发语言有高阶的架构思维能力；
                                    </li>
                                    <li>精通并掌握Hadoop生态技术栈的使用，熟悉数据仓库建设，熟悉常用的模型设计方法，熟悉数据仓库领域知识和技能，包括元数据管理、主数据管理、数据质量管理等，实战过MongoDB、Redis等上层技术，对SpringCloud/Dubbo等主流微服务技术架构有了解；
                                    </li>
                                    <li>能面向HiveQL、SparkSQL编程，实战过Spark
                                        Streaming、Flink，做离线、实时大数据开发，精通HDFS、Hive、Spark、Flink、MR等主流技术栈的底层原理机制，能利用Hadoop生态技术，基于业务场景做大数据平台架构的设计与部署实施；
                                    </li>
                                    <li>具备高阶的抽象架构思维能力，能横跨多个项目、多个技术团队，做主架构的管控与风险识别，熟练使用git、svn,idea等开发管理工具；</li>
                                    <li>具备在项目一线编码能力，承担核心代码编写，有带团队在项目中进行开发管理经验优先；</li>
                                    <li>熟悉工程机械、生产制造领域业务优先；</li>
                                    <li>能依据项目需求，接受异地出差；</li>
                                    <li>具备熟练的方案编写与汇报能力，有数字化产品落地实施经验者优先。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 8 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责数据中台/BI产品的实施工作，与客户深入沟通，负责数据中台/BI项目的高阶访谈、需求调研、差异分析及个案模型规划，挖掘客户数据分析的现状与需求，确定分析的目标与场景，并提交解决方案；根据用户需求编制分析指标词典，数据建模，组织完成开发说明书；
                                    </li>
                                    <li>为客户提供产品演示、咨询、培训、实施以及技术支持，确保解决方案可以有效的执行并落地；</li>
                                    <li>负责资料验证，确保数据的准确性、完整性；</li>
                                    <li>负责数据中台/BI产品的用户培训；</li>
                                    <li>负责项目各阶段相关文档的产出；</li>
                                    <li>负责数据中台/BI产品的用户培训；</li>
                                    <li>协调内外部资源，解决项目实施及维护过程中出现的问题；</li>
                                    <li>其他上级主管安排的工作。</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>熟练使用SQL，熟悉至少一种数据库（MySQL、Oracle等），对数据仓库、NOSQL和Hadoop等有一定的了解；</li>
                                    <li>熟悉至少一款市场主流的数据中台/BI商业智能产品，或深入参与过数据中台/BI或大数据相关项目的建设；</li>
                                    <li>对数据分析有强烈的兴趣，良好的数据分析能力和习惯，能独立的进行常规的数据分析，善于通过数据发现问题或机会；</li>
                                    <li>有良好的沟通和项目管理能力；思维清晰，有良好的主动思考能力、主动学习能力及沟通表达能力；</li>
                                    <li>能适应出差(短期/附近城市)；</li>
                                    <li>本科及以上学历，有3年以上相关工作经验。；</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20" type="flex" justify="space-between" class="joinCon" v-if="index == 9 && item.show">
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>岗位职责：</h2>
                                <ul>
                                    <li>负责Java类系统需求分析；</li>
                                    <li>负责Java类系统的设计和开发工作；</li>
                                    <li>负责解决系统运行中的故障和性能问题。</li>
                                </ul>
                            </div>
                        </el-col>
                        <el-col :xs="24" :sm="11">
                            <div class="duty">
                                <h2>任职要求：</h2>
                                <ul>
                                    <li>具有扎实的JAVA、JEE基础知识；</li>
                                    <li>熟悉Spring、SpringMVC、Struts、MyBatis、Hibernate等JEE常用框架；</li>
                                    <li>熟悉HTML、JS、Jquery等前端框架；</li>
                                    <li>熟悉WebLogic、Tomcat等JEE中间件、ActiveMQ等消息中间件；</li>
                                    <li>熟练掌握Oralce、Mysql数据库的开发和使用；</li>
                                    <li>熟悉Linux操作系统的常用命令；</li>
                                    <li>熟悉springcloud、dubbo分布式框架者优先；</li>
                                    <li>熟悉前端框架react、vue、angularjs者优先。</li>
                                </ul>
                            </div>
                        </el-col>
                    </el-row>
                </div>

            </div>
        </div>
        <el-dialog title="" :visible.sync="showDialogVisible" center>
            <div class="textDesc">
                <h2>联系我们</h2>
                <div class="caveat">
                    <h3> <i class="el-icon-s-home"></i>江苏圆周数字科技有限公司</h3>
                    <p> <i class="el-icon-location-information"> </i>江苏省镇江市润州区茶砚山路106号阿里云创新中心2楼207</p>
                    <p> <i class="el-icon-phone-outline"> </i>08：30-18：30 —— <a href="tel:0511 8560 8166">0511
                            8560 8166</a></p>
                    <p> <i class="el-icon-mobile-phone"> </i>24H 来电 —— <a href="tel:182 6064 3153">182 6064
                            3153</a></p>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "joinUs",
    data() {
        return {
            showDialogVisible: false,
            form: {
                name: '',
                email: '',
                phone: '',
                message: ''
            },
            workList: [
                { job: 'MES实施工程师', num: '8-15K', workHours: '3~5年', show: false, tags: ['MES', '智能制造', '工业4.0', '数字化工厂', '软件实施'] },
                { job: 'WMS实施工程师', num: '8-15K', workHours: '3~5年', show: false, tags: ['WMS', '智慧仓储', 'AVG', 'ERP', '软件实施'] },
                { job: 'APS实施工程师', num: '10-15K', workHours: '3~5年', show: false, tags: ['APS', '生产高级排程', '生产高级排程', 'MRP', '软件实施'] },
                { job: '数字化转型咨询师', num: '15-30K', workHours: '10年以上', show: false, tags: ['咨询服务', '蓝图', '战略', '解决方案', '推动变革'] },
                { job: '智能制造项目经理', num: '15-25K', workHours: '5~10年', show: false, tags: ['PMP', '项目管理', '解决方案', '智能制造'] },
                { job: '销售总监', num: '20-50K', workHours: '5~10年', show: false, tags: ['销售管理', '客户管理', '团队管理', '市场洞察', '扩展培训'] },
                { job: 'OA实施工程师', num: '8-15K', workHours: '3~5年', show: false, tags: ['PMP', '流程优化', '软件实施', 'OA系统', '数据库', '软件开发'] },
                { job: '大数据开发工程师', num: '10-25K', workHours: '3~5年', show: false, tags: ['数据分析', '数据建模', '数据仓库', '数据架构', 'Hadoop', 'MongoDB'] },
                { job: '高级BI实施顾问', num: '15-25K', workHours: '3~5年', show: false, tags: ['PMP', '需求调研', '数据分析', 'BI', '大数据', '软件实施'] },
                { job: 'java开发工程师', num: '8-18K', workHours: '3~5年', show: false, tags: ['Springboot', 'Java', 'Mybatis', 'Vue', '数据库', '分布式', 'Nginx'] },
            ],
            rules: {
                name: [{
                    required: true, message: '姓名不能为空', trigger: 'blur'
                }],
                phone: [{
                    required: true, message: '电话不能为空', trigger: 'blur'
                }]
            }
        };
    },
    created() { },
    methods: {
        showItemCon(item) {
            item.show = !item.show
        }

    },

};
</script>
  
<style lang="scss" scoped>
.joinUs {
    .banner {
        height: 700px;
        background: url("@/assets/images/joinus/banners.jpg") no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;




    }

    .textDesc {
        h2 {
            text-align: center;
            margin-bottom: 30px;
        }

        .caveat {
            margin-bottom: 20px;
            border: 1px solid #f5f5f5;
            padding: 10px 20px;

            i {
                margin-right: 5px;
                font-size: 20px;
                color: #04aaeb;
            }

            h3 {
                font-size: 15px;
                margin-bottom: 10px;
                color: #000;
            }

            p {
                line-height: 30px;
                font-size: 14px;
                color: #000;

                a {
                    text-decoration: none;
                    color: #000;
                }

                a:hover {
                    color: #e6a23c;
                }
            }
        }

    }

    .hot {
        line-height: 60px;

        i {
            color: red;
            font-size: 30px;
        }
    }

    .jobList {

        margin-bottom: 30px;
        border: 1px solid #efefef;
        border-radius: 5px;
        position: relative;
        transition: all .3s ease-in-out;

        .jobItem {
            padding: 20px 30px;
            cursor: pointer;

            .jonName {
                font-size: 18px;
                color: #000;

                i {
                    font-style: normal;
                    font-size: 16px;
                    color: #04aaeb;
                }

                .num {
                    margin-left: 5px;
                    color: #f9b800;
                    font-size: 14px;
                }
            }

            .tabs {
                padding-top: 10px;

                span {
                    display: inline-block;
                    padding: 0 10px;
                    border-right: 1px solid #ccc;

                    i {

                        font-size: 12px;
                        padding: 3px 10px;
                        color: #fff;
                        border-radius: 5px;
                        font-style: normal;
                        border: 1px solid #fff;

                    }

                    .bkcol0,
                    .bkcol5 {
                        /*  background: rgb(144, 147, 153, 0.6); */
                        color: #909399;
                        background: #f4f4f5;
                        border-color: #d3d4d6;
                    }

                    .bkcol1,
                    .bkcol6 {
                        /*  background: rgb(64, 158, 255, 0.6); */
                        color: #409eff;
                        background: #ecf5ff;
                        border-color: #b3d8ff;
                    }

                    .bkcol2,
                    .bkcol7 {
                        /*  background: rgb(103, 194, 58, 0.6); */
                        color: #67c23a;
                        background: #f0f9eb;
                        border-color: #c2e7b0;
                    }

                    .bkcol3,
                    .bkcol8 {
                        /*     background: rgb(230, 162, 60, 0.6); */
                        color: #e6a23c;
                        background: #fdf6ec;
                        border-color: #f5dab1;
                    }

                    .bkcol4,
                    .bkcol9 {
                        /*      background: rgb(245, 108, 108, 0.6); */
                        color: #f56c6c;
                        background: #fef0f0;
                        border-color: #fbc4c4;
                    }


                }

                span:first-child {
                    padding-left: 0;

                }

                span:last-of-type {
                    border-right: 1px solid #fff;
                }

                .contactsUsbtn {
                    margin-left: 30px;
                    float: right;
                }


            }

            .address {
                float: right;

                i {
                    font-size: 16px;
                    color: #04aaeb;
                    font-style: normal;
                    display: inline-block;
                    margin-left: 10px;
                }

            }
        }

        .joinCon {
            border-top: 1px solid #f1f1f1;
            padding: 20px 20px 30px 50px;
            flex-wrap: wrap;

            .duty {
                font-size: 14px;

                h2 {
                    margin: 10px 0;
                    font-size: 16px;
                }

                ul {
                    list-style-type: decimal;

                    li {
                        line-height: 25px;
                        letter-spacing: 1px;
                        color: #a3a3a3;
                    }
                }

            }
        }

    }

    .jobList:hover {
        box-shadow: 0px 5px 25px 0px rgba(0, 100, 255, 0.2);
    }
}
</style>
  