<template>
    <div class="intelligentsolution">
        <div class="banner">
            <div class="solutionText">
                <h2 class="animate__animated animate__slideInLeft">智能制造解决方案</h2>
                <div class="animate__animated animate__slideInRight">
                    <h3>助力制造企业实现智慧转型</h3>
                    <p>从诊断咨询、规划设计、实施落地到持续迭代全面打通价值链，解决个性化大规模生产、快速变化的市场、在制库存高、周转效率低等核心痛点。助力企业走在“智造”前沿，实现数据驱动的全价值链卓越运营及行业示范引领。
                    </p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="industry padUpDown animate__animated animate__fadeInUp">
                <div class="pro_title">
                    <span class="chName">行业趋势</span>
                </div>
                <h3>（1）“数据进行驱动”：数据可以成为智能技术应用关键使能</h3>
                <p>随着传统生产要素的逐步数字化，数控机床机床和工业机器人的广泛应用和深入联系，产生了大量的工业数据，研发和运营等制造业务逐步转移到数字空间，进一步加快了工业数据的积累。此外，大数据、人工智能等技术的突破和融合应用为大规模工业数据挖掘分析提供了有效的手段。
                </p>
                <h3>（2）“虚实融合”： 超越数字空间中的实际生产</h3>
                <p>随着数字传感、物联网、云计算、系统建模、信息融合、虚拟现实等技术的推广应用，实现了物理系统与数字空间的全面互联和深度协同，以及这一过程中的智能分析和决策优化。它使工业领域能够高精度地描绘真实的生产过程并在数字空间中实时映射，用数字比特代替物理原子更高效、近乎零成本地进行验证分析和预测优化，进而用获得的更好的结果或决策来控制和驱动真实的生产过程。数字化工厂是物理世界在数字空间中的等价映射，能够实时、高保真、高集成度地模拟物理实体在虚拟空间中的状态，已经成为工业领域实现虚实融合的关键环节。
                </p>
                <h3>（3）“柔性敏捷”：柔性制造将成为目前的主导模式，消费模式正逐步由标准化、单一统一向定制化、个性差异化转变。</h3>
                <p>例如，服装行业正积极从事各种成衣的网上定制，家具行业正大力推动家居用品的全方位定制，汽车行业正加快探索乘用车用户直连制造，钢铁行业小批量订单需求增长。传统的大规模生产模式不能在可控成本范围内满足敏捷响应和个性化需求的快速交付。
                </p>
                <h3>（4）“全球协作”：单点优化走向全球协作变革。</h3>
                <p>随着5G、物联网等网络安全技术的全面发展应用，泛在互联，万物互联已成为数字经济时代的曲型特征，网络环境使得中国制造管理系统分析可以得到不断超越时空的限制学生进行更广泛地连接，将人、设备、系统和产品等要素连接起来，打通全要素、全价值链和全产业链的“信息孤岛”，使数据我们能够在不同控制系统、不同公司业务和不同影响企业文化之间高效流动。
                </p>

            </div>

        </div>
        <div class="operatchanges padUpDown">
            <div class="pro_title">
                <span class="chName">业务挑战</span>
            </div>
            <div class="container">
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="12" class="type_item">
                        <el-card shadow="hover" class="card">
                            <div class="imgbox">
                                <div class="imgbox"> <i class="iconfont icon-dengta"></i> </div>
                            </div>
                            <h3>新常态</h3>
                            <ul>
                                <li>人力资源、环境成本飙升，显性成本优势下降</li>
                                <li>排浪式消费已经过去，小规模、多品种成为市场主流</li>
                                <li>产品迭代变化速度快，个性化需求日益显著</li>
                                <li>企业利润率单薄，粗放式的增长已走到尽头</li>
                                <li>企业竞争进入新常态</li>
                            </ul>
                        </el-card>
                    </el-col>
                    <el-col :xs="24" :sm="12" class="type_item">
                        <el-card shadow="hover" class="card">
                            <div class="imgbox"><i class="iconfont icon-conus"></i></div>
                            <h3>建设挑战</h3>
                            <ul>
                                <li>普遍面临战略不明确、路径不清晰、过程方法缺失、价值难获取等共性问题和挑战</li>
                                <li>多数企业数字化工厂处于补齐自动化、提升数字化、实践网络化、导入智能化等特征的阶段，实现了改善及局部最优，解决了此前遇到的问题</li>
                                <li>人才是打造数字化工厂的关键基础，数字化工厂打造阶段需要重视复合型人才梯队培养</li>
                            </ul>
                        </el-card>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="prescription padUpDown">
            <div class="pro_title">
                <span class="chName">解决方案</span>
            </div>
            <div class="container">
                <div class="bigdataview">
                    <el-tabs v-model="activeName1" type="card">
                        <el-tab-pane label="对标诊断评估" name="first">
                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="card">
                                        <h3>对标诊断评估</h3>
                                        <p>对标本行业或相近标杆智慧工厂及数字化转型最佳实践从业务、技术、组织和人才等维度找到差距挖掘改善潜力，从企业运营分析体系，业务流，实物流，信息流等进行瓶颈分析及根因识别，为后续整体规划，业务变革,精益改善及数字化建设与优化等指明方向，共创改进目标与计划。
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="整体规划" name="second">
                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="card">
                                        <h3>数字工厂整体规划</h3>
                                        <p>结合企业内外部环境战略解码、价值牵引，进行智能制造顶层设计，制定关键价值动因及运营体系。整体规划融合精益规划、自动化规划、数字化规划及智能化的技术应用规划，立足“提质、增效、降本、减存以及生产模式创新”的目标，充分结合工厂实际需求，打造适合于工厂的总体设计和规划方案，实现规划和设计方向的前瞻性和落地的可实施性。
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="落地实施" name="third">
                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="card">
                                        <h3>数字化工厂落地实施</h3>
                                        <p>为企业提供从数字化工厂的规划设计到落地实施的一站式交钥匙工程，覆盖数字化工厂研发设计、生产制造、供应链管理、节能减排、质量管理、设备管理、运营管理等重点领域，结合APS、MES、WMS、TMS、SCADA、大数据分析等实现数字化卓越制造。
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="自动化解决方案" name="fourth">
                            <el-row :gutter="20" class="type_wrap animate__animated animate__fadeInRight">
                                <el-col :xs="24" :sm="16" class="type_item">
                                    <div class="card">
                                        <h3>自动化解决方案</h3>
                                        <p>提供智能化设备与数据驱动的自动化解决方案，覆盖从概念方案到实施集成全流程，包括整合的AGV、机器人自动化、智慧仓储、智慧物流和运动控制方面的解决方案，将创新技术和产业深度融合，做到跨行业、跨领域深化应用。
                                        </p>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
        <div class="waysAdvantage padUpDown">
            <div class="pro_title">
                <span class="chName">方案优势</span>
            </div>
            <div class=" container">
                <el-row :gutter="20" class="type_wrap">
                    <el-col :xs="24" :sm="6" class="type_item" v-for="(item, index) in valueDatas" :key="index">
                        <div class="card">
                            <div class="imgbox">
                                <img alt="" :src="require(`@/assets/images/solution/way${index + 1}.jpg`)">
                            </div>
                            <h3>{{ item.title }}</h3>
                            <p>{{ item.desc }}</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "intelligentsolution",
    data() {
        return {
            activeName1: 'first',
            valueDatas: [
                { title: '更懂甲方', desc: '团队成员有原大型集团智能制造建设CIO、智能制造行业专家、资深实施落地顾问；丰富的实战落地能力，以落地为原则，精准切入客户痛点，导师级引导并帮助客户实施落地。' },
                { title: '标杆示范引领', desc: '50+智能制造标杆工厂实战经验，萃取精华，助力客户少走弯路，实现弯道超车。' },
                { title: '一站式解决企业痛点', desc: '牵引和趋动企业转型融合升级，实现效率提升、产品增值、流程再造、生态构建。咨询辅导+自主研发的APS、MES、WMS等系统的落地。' },
                { title: '体系赋能', desc: '辅助企业建立精益生产、支撑制度、流程重塑、数字化人才培育等体系建设。' },
            ]
        };

    },
};
</script>
  
<style lang="scss" scoped>
.intelligentsolution {
    .banner {
        width: 100%;
        height: 700px;
        background: url("@/assets/images/solution/banner.jpg") no-repeat;
        background-size: cover;
        position: relative;
    }

    .pro_title {
        font-size: 14px;
        font-weight: 400;
        color: #666;
        text-align: center;
        margin-bottom: 30px;

        span {
            display: block;
        }

        .chName {
            font-size: 26px;
            font-weight: bolder;
            color: #333;
            margin-bottom: 20px;
        }
    }

    .industry {
        h3 {
            font-size: 14px;
            color: #333;
            font-weight: 600;

        }

        p {
            font-size: 14px;
            line-height: 26px;
            text-indent: 2em;
            margin: 5px 0 10px;
            padding: 0 20px;
        }

    }

    //业务挑战
    .operatchanges {
        background: #f5f5f5;

        .card {
            height: 260px;
            text-align: center;
            border: unset;
            background-color: unset;
            padding: 20px 0;

            .imgbox {
                width: 100px;
                margin: 0 auto;

                i {
                    font-size: 60px;
                    color: #f9b800;
                }
            }

            h3 {
                color: #333;
                font-size: 17px;
                margin-bottom: 10px;
                margin-top: 15px;
            }

            ul {
                margin: 0 auto;
                width: 70%;

                li {
                    text-align: left;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);
        }
    }

    //解决方案
    .prescription {
        background: url("@/assets/images/solution/waybanner1.jpg") no-repeat;
        background-size: cover;
        color: #fff;

        .pro_title .chName {
            color: #fff;
        }

    }

    .waysAdvantage {
        background-color: #f7f7f7;

        .card {
            height: 340px;
            text-align: center;
            -webkit-transition: all 0.5s linear;
            transition: all 0.5s linear;
            background: #fff;
            margin-bottom: 10px;

            .imgbox {

                width: 100%;
                height: 140px;
                overflow: hidden;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                    transition: All 0.4s ease-in-out;
                    -webkit-transition: All 0.4s ease-in-out;
                    -moz-transition: All 0.4s ease-in-out;
                    -o-transition: All 0.4s ease-in-out;
                }
            }

            h3 {
                color: #333;
                font-size: 17px;
                margin: 30px 20px 10px;

            }

            p {
                font-size: 12px;
                line-height: 20px;
                margin: 0 20px;
            }
        }

        .card:hover {
            -webkit-transform: translate(0, -10px);
            transform: translate(0, -10px);

            img {
                transform: scale(1.2);

            }
        }
    }



}
</style>
  